
<div mclass="question-card">

  <div mclass="help">
    <help [answer]="answer"></help>
  </div>

  <div mclass="question-label">
    <div style="display: none">
      {{answer.question.value.key}}
    </div>
    <span mclass="question-label-text"
          [ngClass]="{'disabledQuestion':getDisabled()}">
        <span *ngIf="showFriendlyLabel">{{getQuestionNumber()}}.</span>
        {{answer.question.value.label}}
      </span>
  </div>

  <div mclass="answer-widget">
    <button cclass="picker" mclass="picker" mat-stroked-button color="primary"
            [disabled]="getDisabled()"
            (click)="onOpenDialog()">
      <mat-icon>arrow_drop_down</mat-icon>
      {{selectedOption?selectedOption.label:""}}
    </button>
  </div>

</div>


