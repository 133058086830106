import {EAppReferenceType} from "../../../model/cg/core/AppReferenceType";
import {AppCluster} from "../../../model/app.cluster/AppCluster";
import {IFirebaseConnection} from "../../FirebaseConnection";
import {AppClusterAnswers, IAppClusterAnswers} from "../../../model/app.cluster/AppClusterAnswers";
import {FirebaseMMValueObject} from "./FirebaseMMValueObject";
import {ILogger} from "../../../log/Logger";
import {LoggerFactory} from "../../../log/LoggerFactory";
import {EProductType} from "../../../model/ProductType";

export class FirebaseClusterAnswers {

  private static _log: ILogger = LoggerFactory.build('FirebaseClusterAnswers');

  static async hasAnyAnswers(firebaseConnection: IFirebaseConnection, clientKey: string, propertyKey: string, productType: EProductType){
    const path = `/${clientKey}/answer-clusters/${productType}/${propertyKey}/${EAppReferenceType.answer_cluster}`;
    const answerCluster = await firebaseConnection.database2.getValue(path);

    return (!!answerCluster);
  }

  static async read(firebaseConnection: IFirebaseConnection, clientKey: string, propertyKey: string, productType: EProductType, cluster: AppCluster): Promise<IAppClusterAnswers|null> {
    const path = this._getPathFromCluster(clientKey, propertyKey, productType, cluster);

    const answer: IAppClusterAnswers = await firebaseConnection.database2.getValue<IAppClusterAnswers>(path);
    if(!answer || !answer.answers) {
      return AppClusterAnswers.buildValue(cluster.value._self.id);
    }

    return answer;
  }

  private static _getPathFromCluster(clientKey: string, propertyKey: string, productType: EProductType,  cluster: AppCluster): string {
    return `/${clientKey}/answer-clusters/${productType}/${propertyKey}/${EAppReferenceType.answer_cluster}/${EAppReferenceType.answer_cluster}:${cluster._self.value.id}`;
  }

  static async write(firebaseConnection: IFirebaseConnection, clientKey: string, propertyKey: string, productType: EProductType, answers: AppClusterAnswers) {
    const path = this._getPathFromAnswers(clientKey, propertyKey, productType, answers)
    FirebaseMMValueObject.addUpdate(firebaseConnection, answers);
    return firebaseConnection.database2.setValue(path, answers.value);
  }

  private static _getPathFromAnswers(clientKey: string, propertyKey: string, productType: EProductType, answers: AppClusterAnswers): string {
    return `/${clientKey}/answer-clusters/${productType}/${propertyKey}/${EAppReferenceType.answer_cluster}/${answers._self.toString}`;
  }
}
