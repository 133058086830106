<div mclass="question-card">

  <div mclass="help">
    <help [answer]="answer"></help>
  </div>

  <div mclass="question-label">
    <div style="display: none">
      {{answer.question.value.key}}
    </div>
    <span mclass="question-label-text"
          [ngClass]="{'disabledQuestion':formControl.disabled}">
        <span *ngIf="showFriendlyLabel">{{getQuestionNumber()}}.</span>
        {{answer.question.value.label}}
      </span>
  </div>

  <div mclass="answer-widget">

    <mat-form-field cclass="mat-form-field">
      <input matInput
             type="number"
             [(ngModel)]="model"
             [formControl]="formControl"
             style="text-align: right;"
      >
      <span matSuffix [ngClass]="{'disabledQuestion':formControl.disabled}">&nbsp;CM</span>
      <mat-error *ngIf="formControl&&formControl.hasError('required')">
        <strong>Required</strong>
      </mat-error>
      <mat-error *ngIf="formControl&&formControl.hasError('min')">
        <strong>Are you sure? (Usually > {{formControl.errors["min"].min}}&nbsp;CM)</strong>
      </mat-error>
      <mat-error *ngIf="formControl&&formControl.hasError('max')">
        <strong>Are you sure? (Usually < {{formControl.errors["max"].max}}&nbsp;CM)</strong>
      </mat-error>
    </mat-form-field>

  </div>

</div>
