import {Component, OnInit, ViewChild} from '@angular/core';
import {ILogger} from "../javascript.lib.mojo-base/log/Logger";
import {LoggerFactory} from "../javascript.lib.mojo-base/log/LoggerFactory";
import {MatDialog} from "@angular/material/dialog";
import {
  ConfirmCancelDialogComponent
} from "../common/module.base-components/component.confirm-cancel-dialog/confirm-cancel-dialog";
import {NocoDbProduct} from "../javascript.lib.mojo-base/nocodb/NocoDbProduct";
import {AppClusterType} from "../javascript.lib.mojo-base/model/app.cluster/AppClusterType";
import {AppCluster} from "../javascript.lib.mojo-base/model/app.cluster/AppCluster";
import {FirebaseConnectionService} from "../common/service.firebase-connection/FirebaseConnectionService";
import {FirebaseCluster} from "../javascript.lib.mojo-base/firebase/realtime-database/answer-clusters/FirebaseCluster";
import {AppParentChild} from "../javascript.lib.mojo-base/model/app.cluster/AppParentChild";
import {
  FirebaseParentChild
} from "../javascript.lib.mojo-base/firebase/realtime-database/answer-clusters/FirebaseParentChild";
import {AppParentChildSet} from "../javascript.lib.mojo-base/model/app.cluster/AppParentChildSet";
import {AppTypedReference} from "../javascript.lib.mojo-base/model/cg/core/AppTypedReference";
import {AppClusterSet} from "../javascript.lib.mojo-base/model/app.cluster/AppClusterSet";
import {ActivatedRoute, Router} from "@angular/router";
import {SessionContextProvider} from "../service.session-context/session-context-provider";
import {AppPageDefinitionSet} from "../javascript.lib.mojo-base/model/app/AppPageDefinitionSet";
import {AppClusterNode} from "../javascript.lib.mojo-base/model/app.cluster/AppClusterNode";
import {
  ClusterAddDialogComponent,
  IClusterAddDialogParams,
  IClusterAddDialogResponse
} from "./component.cluster-add-dialog/cluster-add-dialog";
import {environment} from "../environments/environment";
import {EProductType} from "../javascript.lib.mojo-base/model/ProductType";
import {AppClusterTypeSet} from "../javascript.lib.mojo-base/model/app.cluster/AppClusterTypeSet";
import {HttpClient} from "@angular/common/http";
import {IProxyResponse} from "../javascript.lib.mojo-base/firebase/functions/ProxyResponse";
import {IEvaluationState} from "../javascript.lib.mojo-base/model/evaluation/EvaluationStatus";
import type {FireworksDirective, FireworksOptions} from "@fireworks-js/angular";
import {timer} from "rxjs";
import {Subscriptions2} from "../javascript.lib.mojo-base/util/Subscriptions2";
import {AppRouteManifest} from "../app/AppRouteManifest";
import {PropertyService} from "../service.property/property-service";
import {SessionContextState} from "../common/service.session-context/BaseSessionContext";
import {AppStorage} from "../app/AppStorage";
import {HierarchyBuilder} from "./hierarchy-builder";
import {AlertDialogComponent} from "../common/module.base-components/component.alert-dialog/alert-dialog";
import {AuditScoreCalculator} from "../javascript.lib.mojo-base/model/scoring/AuditScoreCalculator";
import {AuditService} from "../service.audit/audit-service";
import {AppAudit, EAppAuditState} from "../javascript.lib.mojo-base/model/AppAudit";

@Component({
  selector: 'page-audit',
  styleUrls: ['page-audit.component.scss'],
  templateUrl: './page-audit.component.html'
})
export class PageAuditComponent implements OnInit {

  private _log: ILogger = LoggerFactory.build( 'PageAuditComponent' );

  public canEditClusters = environment.productConfig.canEditClusters;

  public propertyKey: string = null;
  public productType: EProductType = null;
  public completed: boolean = false;

  clusterType: AppClusterType;
  cluster: AppCluster;

  public applicableChildrenTypes: AppClusterType[] = [];
  public children: AppClusterSet = new AppClusterSet( {});
  public hasQuestionsForEvaluation: boolean = false;
  public parentChildHierarchy: AppParentChildSet;
  public hierarchyRoot: AppClusterNode;
  public initCompleted = false;
  public initInProgress: boolean = false;
  public subscriptions = new Subscriptions2();

  submittingAsCompleted = false;

  public state: EAppAuditState = EAppAuditState.unknown;

  @ViewChild('fireworks') fireworks?: FireworksDirective;
  fireworksEnabled = false;
  options: FireworksOptions = {
    opacity: 0.5
  }

  private async _getClusterSet(): Promise<AppClusterSet> {
    if (!this.propertyService?.propertyContext?.property) {
      return;
    }

    const hierarchyBuilder: HierarchyBuilder = new HierarchyBuilder(this.propertyService.propertyContext.property,
      this.firebase, this.sessionContext, this.propertyService);

    this.clusterType = NocoDbProduct.INSTANCE.getClusterTypeRoot(this.productType);
    this.cluster = await hierarchyBuilder.getOrBuild(this.clusterType);

    return await FirebaseCluster.readAll(this.firebase, this.sessionContext.clientKey, this.propertyKey, this.productType);
  }

  private async _updateCompletedSections() {

    const propertyKey = this.propertyService.propertyContext.propertyKey;
    let isAuditComplete:boolean = true;
    let isSectionComplete:boolean = true;

    for (const section of this.hierarchyRoot.children) {
      if (this.isOptionalAndMarkedExcluded(section)) {
        // skip optional section that's marked as not included
        continue;
      } else {
        // check if any subsections in the section are incomplete -> it, and the parent section is incomplete.
        if (0 !== section.children.length) {
          for (const subSection of section.children) {

            // skip optional sub-section that's marked as not included
            if (this.isOptionalAndMarkedExcluded(subSection)) {
              continue;
            } else if (!subSection.cluster.value.completed) {
              isSectionComplete = false;
            }
          }
        } else {
          isSectionComplete = false;
        }
      }

      // if any section is incomplete, the whole audit is incomplete
      if (isSectionComplete === false) {
        isAuditComplete = false;
      }

      // persist any changes
      if (section.cluster.value.completed != isSectionComplete) {
        section.cluster.value.completed = isSectionComplete;
        await FirebaseCluster.write(this.firebase, this.sessionContext.clientKey, propertyKey, this.productType, section.cluster);
      }
    }

    if (isAuditComplete && AppAudit.isWithUser(this.state)) {
      this.state = EAppAuditState.complete;
    }

    if (!isAuditComplete && AppAudit.isWithUser(this.state)) {
      if (await this.auditService.hasAnyAnswers(this.propertyKey, this.productType)) {
        this._log.info("in progress");
        this.state = EAppAuditState.inProgress;
      } else {
        this._log.info("created");
        this.state = EAppAuditState.created;
      }
    }

    if (isAuditComplete !== this.hierarchyRoot.cluster.value.completed) {
      this.hierarchyRoot.cluster.value.completed = isAuditComplete;
      await FirebaseCluster.write( this.firebase, this.sessionContext.clientKey, propertyKey, this.productType, this.hierarchyRoot.cluster );
      await this.auditService.complete(propertyKey);
    } else {
      await this.auditService.setState(propertyKey, this.state);
    }
  }

  private isOptionalAndMarkedExcluded(appClusterNode: AppClusterNode) : boolean {
    return appClusterNode.cluster.value.optional && !appClusterNode.cluster.value.include;
  }

  private async _onInit(propertyKey: string|null = null) {
    this.initCompleted = false;

    if (!propertyKey) {
      AppRouteManifest.SELECT_PROPERTY.navigate(this.router);
      return
    }

    await this.propertyService.getOrBuildPropertyContext(propertyKey);

    this.productType = this.propertyService.productType;
    this.propertyKey = this.propertyService.propertyContext.propertyKey;
    this.state = await this.auditService.getState(propertyKey);

    const nocoDbProduct = NocoDbProduct.INSTANCE;

    let clusterSet = await this._getClusterSet();

    const pageDefinitions: AppPageDefinitionSet = nocoDbProduct.clusterQuestions.toPageDefinitions(this.cluster, nocoDbProduct.evaluationQuestions, this.propertyService.propertyContext.property.value.countryCode);

    if (pageDefinitions.value.length === 0) {
      this.hasQuestionsForEvaluation = false;
    } else {
      this.hasQuestionsForEvaluation = true;
    }

    this.applicableChildrenTypes = nocoDbProduct.getClusterTypeChildren(this.clusterType);
    this.parentChildHierarchy = await FirebaseParentChild.readReferences(this.firebase, this.sessionContext.clientKey, this.propertyKey, this.productType);
    const childrenReferences: AppTypedReference[] = this.parentChildHierarchy.getChildren(this.cluster._self);
    this.children = clusterSet.getSubset(childrenReferences);

    const clusterTypes: AppClusterTypeSet = new AppClusterTypeSet(nocoDbProduct.clusters, nocoDbProduct.productClusters);
    this.hierarchyRoot = AppClusterNode.buildHierarchy(this.cluster, clusterSet, this.parentChildHierarchy, clusterTypes);

    await this._updateCompletedSections();
    this.initCompleted = true;

    this._showInstructions();
  }

  private async _tryOnInit(propertyKey: string|null = null) {
    if( this.initInProgress ) {
      this._log.warn( 'this.initInProgress', this.initInProgress );
      return;
    }

    try {
      this.initInProgress = true;
      await this._onInit(propertyKey);
    } finally {
       this.initInProgress = false;
    }
  }

  async ngOnInit() {
    this.route.paramMap.subscribe(  (params) => {
      this._onParam();
    });
  }

  private async _onParam() : Promise<void> {
    const propertyKey = await AppRouteManifest.AUDIT.getPropertyKey( this.route );
    this.subscriptions.subscribe(this.sessionContext.stateSubject, (state : SessionContextState) => {
      if (state === SessionContextState.UserIsReady) {
        if (this.sessionContext.isAdministrator) {
          this._handleAdministratorUserReady(propertyKey);
        } else {
          this._handleUserReady(propertyKey);
        }
      }
    });
  }

  private async _handleAdministratorUserReady(propertyKey: string): Promise<void> {
    await this.sessionContext.reloadCurrentUser();
    if (!this.sessionContext.user.hasPropertyKey(propertyKey)) {
      return;
    }
    return this._tryOnInit(propertyKey);
  }

  private async _handleUserReady(propertyKey: string): Promise<void> {
    if (!this.sessionContext.user.hasPropertyKey(propertyKey)) {
      return;
    }
    AppStorage.setPropertyKey(this.sessionContext.user.userUid, propertyKey);
    return this._tryOnInit(propertyKey);
  }

  private async _onDeleteChild( child: AppCluster) {

    this._log.debug( 'onDeleteChild', 'child', child );

    const parentChild: AppParentChild = this.parentChildHierarchy.getParentChild( this.cluster._self, child._self );
    if( !parentChild ) {

      this._log.error( 'onDeleteChild', 'this.cluster._self', this.cluster._self, 'child._self', child._self );
    } else {

      parentChild.value.trashed = true;
      await FirebaseParentChild.writeReference( this.firebase, this.sessionContext.clientKey, this.propertyKey, this.propertyService.productType, parentChild );

      const clusterSet = await FirebaseCluster.readAll( this.firebase, this.sessionContext.clientKey, this.propertyKey, this.propertyService.productType );
      const childrenReferences: AppTypedReference[] = this.parentChildHierarchy.getChildren( this.cluster._self );
      this.children = clusterSet.getSubset( childrenReferences );
      this._log.debug( 'this.children', this.children );
    }

  }

  async onDeleteChild( child: AppCluster) {

    const dialog = ConfirmCancelDialogComponent.open( this.dialog, {
      message: `Remove area '${child.value.name}'?`,
      title: 'Remove area?',
    });

    dialog.afterClosed().subscribe(result => {

      this._log.debug( 'result', result );
      if( result ) {

        this._onDeleteChild(child);
      }
    });
  }

  async _onDeleteCluster( parent: AppClusterNode, childForDeletion: AppClusterNode ) {

    this._log.debug( 'parent', parent );
    this._log.debug( 'childForDeletion', childForDeletion );

    childForDeletion.parentRelation.value.trashed = true;
    const propertyKey = this.propertyService.propertyContext.propertyKey;
    await FirebaseParentChild.writeReference( this.firebase, this.sessionContext.clientKey, propertyKey, this.propertyService.productType, childForDeletion.parentRelation );

    const updatedChildren: AppClusterNode[] = [];
    for( const e of parent.children ) {

      if( e === childForDeletion ) {
        continue;
      }
      updatedChildren.push( e );
    }

    parent.children = updatedChildren;

    this._updateCompletedSections();
  }

  async onDeleteCluster( parent: AppClusterNode, childForDeletion: AppClusterNode ) {

    this._log.debug( 'parent', parent );
    this._log.debug( 'childForDeletion', childForDeletion );

    const childCluster = childForDeletion.cluster;

    const dialog = ConfirmCancelDialogComponent.open( this.dialog, {
      message: `Remove area '${childCluster.value.name}'?`,
      title: 'Remove area?',
    });

    dialog.afterClosed().subscribe(result => {

      this._log.debug( 'result', result );
      if( result ) {

        this._onDeleteCluster( parent, childForDeletion );
      }
    });

  }

  async onAddSubSection( parent: AppClusterNode ) {

    this._log.debug( 'parent', parent );

    const product = NocoDbProduct.INSTANCE;
    const parentType = product.getClusterType(this.productType, parent.cluster);
    const applicableTypes = product.getClusterTypeChildren( parentType );

    const params: IClusterAddDialogParams = {

      clusterType: null,
      name: '',
      applicableTypes,
      responsibility: parent.cluster.value.responsibility,
    };


    const dialog = ClusterAddDialogComponent.open( this.dialog, params);

    dialog.afterClosed().subscribe(async (response: IClusterAddDialogResponse|null) => {

      this._log.debug( 'dialog.afterClosed().subscribe', 'response', response );
      if(response) {

        const newChild = response.clusterType.buildAppCluster(response.name);
        await FirebaseCluster.write(this.firebase, this.sessionContext.clientKey, this.propertyKey, this.propertyService.productType, newChild);

        const parentChild = AppParentChild.buildNew( parent.cluster, newChild );
        await FirebaseParentChild.writeReference(this.firebase, this.sessionContext.clientKey, this.propertyKey, this.propertyService.productType, parentChild);

        {
          const childClusterNode = new AppClusterNode(newChild, parentChild);
          parent.children.push( childClusterNode );
        }

        await this._updateCompletedSections();

      }
    });


  }

  async onAddSection() {
    await this.onAddSubSection( this.hierarchyRoot );
  }

  async onIncludeCluster( parent: AppClusterNode, childForDeletion: AppClusterNode ) {
    await this._updateCompletedSections();
  }

  private async _evaluationCompleted() {
    const propertyId: string = this.propertyService.propertyContext.propertyKey;
    const userUid = this.sessionContext.user.userUid;
    const userEmail = this.sessionContext.username;
    const propertyName: string = this.propertyService.propertyContext.property.value.name;
    const product: string = this.propertyService.propertyContext.product.title;

    this.state = EAppAuditState.submitted;
    await this.auditService.submit(propertyId);

    const dialog = AlertDialogComponent.show(this.dialog,
      "Congratulations!",
      `Your audit for ${propertyName} has been successfully submitted.`,
      "OK"
    );

    dialog.afterClosed().subscribe(result => {
      if(result) {
        this._showFireworks();
      }
    });

    const proxy = await this.sessionContext.buildAuthenticatedProxy( this.httpClient );
    const message = `Hello there! ${userEmail} has just submitted a _${product}_ audit for *${propertyName}* :classical_building: :tada: (on ${environment.name} environment)`;
    const response: IProxyResponse<IEvaluationState>
      = await proxy.evaluationCompleted(
        this.sessionContext.clientKey,
        this.propertyService.propertyContext.product.productType.toString(), propertyId, userUid, userEmail,
        message);
    // TODO: check that status is set by middleware on submission - it's giving 500s
    if( "0" !== response.status ) {
      //throw response.status;
    }
  }

  private async _showFireworks() {
    this.fireworksEnabled = true
    const aTimer = timer(3000);
    aTimer.subscribe(async val => {
      await this.fireworks?.waitStop();
      this.fireworksEnabled = false;
    });
  }

  private _showInstructions() {
    if (!this.sessionContext.userHasSeenInstructions) {
      AlertDialogComponent.show(this.dialog,
        "Instructions",
        "To complete your audit click into each section and answer the questions.",
        "OK"
      );
      this.sessionContext.userHasSeenInstructions = "Y";
    }
  }

  async onEvaluationCompleted() {
    this._log.debug( "onEvaluationCompleted" );

    const score = new AuditScoreCalculator().calculate(this.hierarchyRoot);
    this._log.debug("onEvaluationCompleted", "score", score);

    const dialog = ConfirmCancelDialogComponent.open( this.dialog, {
      message: "Please confirm that you consider the audit complete, and that you are happy to submit it to be reviewed.",
      title: 'Submit for review?',
      okLabel: 'YES',
      cancelLabel: 'NO'
    });

    dialog.afterClosed().subscribe(result => {
      if( result ) {
        try {
          this.submittingAsCompleted = true;
          this._evaluationCompleted();
        } finally {
          this.submittingAsCompleted = false;
        }
      }
    });
  }

  public canBeSubmitted(): boolean {
    return this.state === EAppAuditState.complete;
  }

  public submitButtonLabel(): string {
    return this.isWithUser() ? "Submit Audit" : "Submitted";
  }

  public isWithUser(): boolean {
    return AppAudit.isWithUser(this.state);
  }

  constructor(public router: Router,
              public route: ActivatedRoute,
              public dialog: MatDialog,
              public sessionContext: SessionContextProvider,
              public auditService: AuditService,
              public propertyService: PropertyService,
              public firebase: FirebaseConnectionService,
              public httpClient: HttpClient) {
  }
}
