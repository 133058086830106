import {IFirebaseConnection} from "../../FirebaseConnection";
import {AppCluster, IAppCluster} from "../../../model/app.cluster/AppCluster";
import {AppTypedReferenceString} from "../../../model/cg/core/AppTypedReference";
import {AppClusterSet} from "../../../model/app.cluster/AppClusterSet";
import {EAppReferenceType} from "../../../model/cg/core/AppReferenceType";
import {FirebaseMMValueObject} from "./FirebaseMMValueObject";
import {EProductType} from "../../../model/ProductType";

export class  FirebaseCluster {

  private static _getPath(clientKey: string, propertyKey: string, productType: EProductType, clusterReference: AppTypedReferenceString|null): string {

    if(clusterReference) {
      return `/${clientKey}/answer-clusters/${productType}/${propertyKey}/${EAppReferenceType.cluster}/${clusterReference}`;
    }

    return `/${clientKey}/answer-clusters/${productType}/${propertyKey}/${EAppReferenceType.cluster}`;
  }

  static async write(firebaseConnection: IFirebaseConnection, clientKey: string, propertyKey: string,
                     productType: EProductType, appCluster: AppCluster): Promise<void> {
    const path = this._getPath(clientKey, propertyKey, productType, appCluster._self.toString);
    if (appCluster?.value?.score === undefined) {
      appCluster.value.score = 0;
    }
    FirebaseMMValueObject.addUpdate(firebaseConnection, appCluster);
    return firebaseConnection.database2.setValue(path, appCluster.value);
  }

  static async read(firebaseConnection: IFirebaseConnection, clientKey: string, propertyKey: string,
                    productType: EProductType, clusterReference: AppTypedReferenceString): Promise<AppCluster>|null {
    const path = this._getPath(clientKey, propertyKey, productType, clusterReference);
    const value = await firebaseConnection.database2.getValue<IAppCluster|null>( path );

    if(!value) {
      return null;
    }

    return new AppCluster(value);
  }

  static async readAll(firebaseConnection: IFirebaseConnection, clientKey: string, propertyKey: string,
                       productType: EProductType): Promise<AppClusterSet> {
    const path = this._getPath( clientKey, propertyKey, productType, null );
    const values = await firebaseConnection.database2.getValue<{ [key: AppTypedReferenceString]: IAppCluster; }|null>( path );

    if(!values) {
      return new AppClusterSet({} );
    }
    return new AppClusterSet(values);
  }
}
