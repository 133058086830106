import {Component, Input, OnInit, QueryList, ViewChildren} from '@angular/core';
import {QuestionCentimetersComponent} from '../question-centimeters/question-centimeters';
import {QuestionIntegerComponent} from '../question-integer/question-integer';
import {AppQuestion} from '../../../javascript.lib.mojo-base/model/AppQuestion';
import {environment} from "../../../environments/environment";
import {AppAnswer} from "../../../javascript.lib.mojo-base/model/AppAnswer";
import {AppProperty} from "../../../javascript.lib.mojo-base/model/AppProperty";
import {AppAnswerPage} from "../../../javascript.lib.mojo-base/model/AppAnswerPage";

@Component({
  selector: 'app-question-list',
  templateUrl: './aspect-question-list.component.html',
  styleUrls: ['aspect-question-list.component.scss'],
})
export class AspectQuestionListComponent implements OnInit {

  @Input() answers: AppAnswer[] = null;
  @Input() pages: AppAnswerPage[] = null;
  @Input() property: AppProperty = null;
  @Input() pageIndex: number = 0;
  @Input() sectionIndex: number = 0;
  @Input() subSectionIndex: number = 0;
  @Input() firstQuestionOnPageIndex: number = 0;

  @ViewChildren(QuestionCentimetersComponent) questionCentimetersComponents: QueryList<QuestionCentimetersComponent>;
  @ViewChildren(QuestionIntegerComponent) questionIntegerComponents: QueryList<QuestionIntegerComponent>;

  questionTypes = {
    TYPE_BOOLEAN: AppQuestion.TYPE_BOOLEAN,
    TYPE_CM_MEASUREMENT: AppQuestion.TYPE_CM_MEASUREMENT,
    TYPE_ENUM: AppQuestion.TYPE_ENUM,
    TYPE_FLOAT: AppQuestion.TYPE_FLOAT,
    TYPE_INTEGER: AppQuestion.TYPE_INTEGER,
    TYPE_LINE: AppQuestion.TYPE_LINE,
    TYPE_PHOTO: AppQuestion.TYPE_PHOTO,
    TYPE_TERNARY: AppQuestion.TYPE_TERNARY,
    TYPE_TEXT: AppQuestion.TYPE_TEXT,
  };

  getPage() : AppAnswer[] {
    return this.pages.find((p) => p.index == this.pageIndex).answers;
  }

  ngOnInit() {
  }

  constructor() { }
}
