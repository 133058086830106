import {IAppProperty, AppProperty} from "../../../model/AppProperty";
import {IFirebaseConnection} from "../../FirebaseConnection";

export class FirebaseProperty {

  private static getPath( clientKey:string, propertyId: string ) {
    return `/${clientKey}/properties/${propertyId}`;
  }

  static async readAll(firebaseConnection: IFirebaseConnection, clientKey: string): Promise<AppProperty[]> {
    const properties: AppProperty[] = [];
    const valuesRead: {[propertyKey: string]: IAppProperty;} = await firebaseConnection.database2.getValue( `${clientKey}/properties` );

    if (valuesRead) {
      for (const propertyKey of Object.keys(valuesRead)) {
        properties.push(new AppProperty(valuesRead[propertyKey], propertyKey));
      }
    }

    return properties;
  }

  static async read(firebase: IFirebaseConnection, clientKey: string, propertyKey: string): Promise<AppProperty|null> {
    const path = this.getPath(clientKey, propertyKey);
    const value: IAppProperty = await firebase.database2.getValue<IAppProperty>(path);

    if (value) {
      return new AppProperty(value, propertyKey);
    }

    return null;
  }

  static async write(firebaseConnection: IFirebaseConnection, clientKey: string, property: AppProperty): Promise<void> {
    const path = this.getPath(clientKey, property.propertyKey);
    return firebaseConnection.database2.setValue(path, property.value);
  }
}
