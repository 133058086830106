import {NocoCluster, NocoClusterId} from "../../nocodb/model/NocoCluster";
import {ValueObject} from "../ValueObject";
import {IMMValueObject, FirebaseValueObject} from "../../firebase/realtime-database/FirebaseValueObject";
import {EAppReferenceType} from "../cg/core/AppReferenceType";
import {AppTypedReference} from "../cg/core/AppTypedReference";
import {AppCluster, IAppCluster} from "./AppCluster";
import {CGIdentifierGenerator} from "../cg/util/CGIdentifierGenerator";
import {FirebaseMetaData} from "../../firebase/realtime-database/FirebaseMetaData";
import {NocoProductCluster} from "../../nocodb/model/NocoProductCluster";
import {NocoProductId} from "../../nocodb/model/NocoProduct";
import {ILogger} from "../../log/Logger";
import {LoggerFactory} from "../../log/LoggerFactory";


export interface IAppClusterType extends IMMValueObject {
  clusterId: NocoClusterId;
  productId: NocoProductId;
  name: string;
}

export class AppClusterType extends ValueObject<IAppClusterType>{

  private static _log: ILogger = LoggerFactory.build('AppClusterType');

  public iconName: string|null = null;
  public optional: boolean = false;
  public essential: boolean = false;
  public weight: number = 1;
  public mobilityWeight: number = 1;
  public visualWeight: number = 1;
  public auditoryWeight: number = 1;
  public cognitiveWeight: number = 1;
  public index: number = 0;

  protected onSetValue(value: IAppClusterType | null) {
  }

  buildAppCluster(name: string|null = null): AppCluster {

    if(!name) {
      name = this.value.name;
    }

    const answerId = CGIdentifierGenerator.generateId();
    const answerValue: IAppCluster = {
      _meta: FirebaseMetaData.buildMeta(),
      _self: {
        id: answerId,
        type: AppCluster.TYPE
      },
      completed: false,
      clusterTypeId: this.value.clusterId,
      name,
      responsibility: 'landlord',
      core: false,
      optional: this.optional,
      include: true,
      essential: this.essential,
      weight: this.weight,
      auditoryWeight: this.auditoryWeight,
      cognitiveWeight: this.cognitiveWeight,
      mobilityWeight: this.mobilityWeight,
      visualWeight: this.visualWeight,
      score: 0,
      comment: '',
    }
    return new AppCluster(answerValue);
  }

  public static build(nocoCluster: NocoCluster, nocoProductCluster?: NocoProductCluster): AppClusterType | null {
    const selfReference = new AppTypedReference( {
      type: EAppReferenceType.QuestionCluster,
      id: nocoCluster.value.Id
    });

    const iAppClusterType: IAppClusterType = {
      _meta: FirebaseMetaData.buildMeta(),
      _self: selfReference.value,
      name: nocoCluster.value.Name,
      productId: nocoCluster.value.ProductId,
      clusterId: nocoCluster.value.Id,
    }

    return (nocoProductCluster) ? new AppClusterType(iAppClusterType, nocoCluster, nocoProductCluster) : null;
  }

  constructor(value: IAppClusterType,
              public nocoCluster: NocoCluster,
              public nocoProductCluster?: NocoProductCluster) {
    super(value);

    if (nocoCluster.value.Icon) {
      this.iconName = nocoCluster.value.Icon;
    }

    if (!nocoProductCluster?.value) {
      return;
    }

    this.essential = nocoProductCluster.value.Essential;
    this.optional = nocoProductCluster.value.Optional;
    this.weight = nocoProductCluster.value.Weight ?? 1;
    this.auditoryWeight = nocoProductCluster.value.AuditoryWeight ?? 1;
    this.cognitiveWeight = nocoProductCluster.value.CognitiveWeight ?? 1;
    this.mobilityWeight = nocoProductCluster.value.MobilityWeight ?? 1;
    this.visualWeight = nocoProductCluster.value.VisualWeight ?? 1;
  }
}
