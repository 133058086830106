import {Component, Inject, Input} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {DialogConfig} from "../../../common/angular/DialogConfig";
import {ILogger} from "../../../javascript.lib.mojo-base/log/Logger";
import {LoggerFactory} from "../../../javascript.lib.mojo-base/log/LoggerFactory";
import {BaseComponentsModule} from "../../../common/module.base-components/base-components.module";
import {MatInputModule} from "@angular/material/input";
import {FormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";

export interface IClusterCommentDialogParams {
  clusterName: string;
  comment: string
}
export interface IClusterCommentDialogResponse {
  comment: string;
}
@Component({
  selector: 'cluster-comment-dialog',
  templateUrl: 'cluster-comment-dialog.html',
  styleUrls: ['cluster-comment-dialog.scss'],
  standalone: true,
  imports: [
    BaseComponentsModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogModule
  ]
})
export class ClusterCommentDialogComponent {

  public static PANEL_CLASS = "cluster-comment-dialog";

  private _log: ILogger = LoggerFactory.build( 'ClusterDetailDialogComponent' );

  public params: IClusterCommentDialogParams;

  static open(dialog: MatDialog, params: IClusterCommentDialogParams): MatDialogRef<ClusterCommentDialogComponent> {

    return dialog.open<ClusterCommentDialogComponent, IClusterCommentDialogParams, IClusterCommentDialogResponse>
    ( ClusterCommentDialogComponent,
      {
        data: params,
        panelClass: ClusterCommentDialogComponent.PANEL_CLASS,
        minWidth: DialogConfig.minWidth,
      });
  }

  onCancelClick(): void {
    this.dialogRef.close(null );
  }

  onOkClick(): void {
    const response: IClusterCommentDialogResponse = {
      comment: this.params.comment
    }
    this.dialogRef.close(response);
  }


  constructor(
    public dialogRef: MatDialogRef<ClusterCommentDialogComponent, IClusterCommentDialogResponse>,
    @Inject(MAT_DIALOG_DATA) params: IClusterCommentDialogParams,
  ) {
    this.params = params;
  }
}
