import {IAppAnswer} from "../model/AppAnswer";
import {EnumeratedConstantReference, EnumeratedConstants} from "../model/EnumeratedConstant";

export class ValueHelper {

  static getNumberValue(answer: IAppAnswer, defaultValue: number) : number {
    if (!answer) {
      return defaultValue;
    }

    const numberAnswer = ValueHelper.valueToNumber(answer.value);
    if(numberAnswer === null) {
      return defaultValue;
    }

    return numberAnswer;
  }

  static valueToNumber(value): number|null {
    if (value === null || typeof value === 'undefined') {
      return null
    }
    if (typeof value === 'number'){
      return value;
    }
    if (typeof value === 'string') {
      if (value.length === 0) {
        return null;
      }
      const number = Number(value);
      if (isNaN(number)) {
        return null;
      }
      return number;
    }
    return null;
  }

  static valueToInteger(value): number|null  {

    if (null === value) {
      return null;
    }

    if ('undefined' === typeof value) {
      return null;
    }

    if ('number' === typeof value) {
      return value;
    }

    if ('string' === typeof value) {
      if (0 === value.length) {
        return null;
      }

      const numberAnswer = parseInt(value, 10);
      if (isNaN(numberAnswer)) {

        console.error( 'isNaN(numberAnswer)', value);
        return null;
      }

      return numberAnswer;
    }

    console.error( 'unhandled type', typeof value, value);
    return null;
  }

  static getIntegerValue(answer: IAppAnswer, defaultValue: number): number {
    if (!answer) {
      return defaultValue;
    }

    const numberAnswer = ValueHelper.valueToInteger(answer.value);
    if(numberAnswer === null) {
      return defaultValue;
    }

    return numberAnswer;
  }

  static getTernaryValue(answer: IAppAnswer): boolean | null {
    if (!answer) {
      return null;
    }

    if (answer.value == EnumeratedConstantReference.notApplicable.codeAsNumber) {
      return null;
    } else if (answer.value == EnumeratedConstantReference.yes.codeAsNumber) {
      return true
    }
    return false;
  }
}
