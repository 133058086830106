<div cclass="property-details-wrapper">
  <mat-icon cclass="property-icon">
    {{icon}}
  </mat-icon>
  <div cclass="property-details">
    <h3>{{label}}</h3>
    <div cclass="property-address">
      <span>{{property?.addressLabel}}</span>
    </div>
  </div>
</div>
