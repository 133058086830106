<dialog-header [title]="'Comment: ' + params.clusterName"></dialog-header>

  <div mat-dialog-content style="" class="mat-typography">

    <div aclass="component-list-item">
      <div style="width: 100%; height: 12px;">
      </div>
    </div>

    <div aclass="component-list-item">
      <div style="width: 100%; height: 8px;">
      </div>
    </div>

    <div>
      <mat-form-field style="width: 100%;">
        <textarea matInput
                  [cdkTextareaAutosize]
                  [(ngModel)]="params.comment"
                  placeholder="Enter your comments ..."
        >
        </textarea>
      </mat-form-field>

    </div>

  </div>

  <dialog-footer>
    <button mat-stroked-button color="primary" (click)="onCancelClick()">
      Cancel
    </button>
    <span style="width: 8px;"></span>
    <button mat-stroked-button color="primary" (click)="onOkClick()">
      Save
    </button>
  </dialog-footer>
