import { AppCluster } from "./AppCluster";
import {AppParentChildSet} from "./AppParentChildSet";
import {AppClusterSet} from "./AppClusterSet";
import {AppParentChild} from "./AppParentChild";
import {AppClusterTypeSet} from "./AppClusterTypeSet";
import {ILogger} from "../../log/Logger";
import {LoggerFactory} from "../../log/LoggerFactory";

export class AppClusterNode {

  private static _log: ILogger = LoggerFactory.build('AppClusterNode');

  children: AppClusterNode[] = [];
  public iconName: string|null = null;
  public index: number = 0;

  public static buildHierarchy(parentCluster: AppCluster,
                               clusters: AppClusterSet,
                               parentChildren: AppParentChildSet,
                               clusterTypes: AppClusterTypeSet): AppClusterNode {

    const parentRelation = parentChildren.findParentOfChild(parentCluster._self);
    const answer = new AppClusterNode(parentCluster, parentRelation);
    const children = parentChildren.getChildren(parentCluster._self);

    let index = 1;
    for(const child of children) {
      const childCluster = clusters.valuesById[child.toString];

      if(!childCluster || !childCluster._self) {
        AppClusterNode._log.warn( '!childCluster || !childCluster._self', 'child', child );
        continue;
      }

      const childNode = AppClusterNode.buildHierarchy(childCluster, clusters, parentChildren, clusterTypes);

      {
        const clusterType = clusterTypes.valueByKey[childCluster.value.clusterTypeId];
        if(!clusterType) {
          AppClusterNode._log.warn( '!clusterType (check product_cluster table)', 'childCluster.value.clusterTypeId', childCluster.value.clusterTypeId);
        } else {
          childNode.iconName = clusterType.iconName;
          childNode.index = index++;
          childNode.cluster.value.optional = clusterType.optional;
          childNode.cluster.value.weight = clusterType.weight;
          childNode.cluster.value.auditoryWeight = clusterType.auditoryWeight;
          childNode.cluster.value.cognitiveWeight = clusterType.cognitiveWeight;
          childNode.cluster.value.mobilityWeight = clusterType.mobilityWeight;
          childNode.cluster.value.visualWeight = clusterType.visualWeight;
          childNode.cluster.value.score = childCluster.value.score;
          childNode.cluster.value.essential = clusterType.essential;
          childNode.cluster.value.name = clusterType.value.name;
          childNode.cluster.value.comment = childCluster.value?.comment;
          answer.children.push(childNode);
        }
      }
    }

    return answer;
  }


  public constructor(public cluster: AppCluster,
                     public parentRelation: AppParentChild|null // null when root
                     ) {
  }
}
