import {IMenuItem} from "../module.base-components/component.side-menu/side-menu.component";
import {MatIcons} from "../util/MatIcons";
import {AppRouteManifest} from "../../app/AppRouteManifest";
import {SessionContextProvider} from "../../service.session-context/session-context-provider";
import {ApplicationContextProvider} from "../../service.application-context/application-context";
import {Router} from "@angular/router";
import {PropertyService} from "../../service.property/property-service";

export class BaseSideMenuModel {
  public menuItems: IMenuItem[] = [];

  public onGoHome() {
    AppRouteManifest.HOME.navigate(this.router);
  }

  protected buildNavLink(matIcon: string, label: string, url: string) :IMenuItem {
    return {
      disabled: () => { return false; },
      matIcon: matIcon,
      label: () => { return label },
      onClick: async () => {
        window.location.href = url;
      }
    };
  }

  protected getHomeMenuItem(): IMenuItem {
    return {
      disabled: () => {
        return false;
      },
      matIcon: MatIcons.icons.home,
      label: () => {
        return 'Home';
      },
      onClick: async () => {
        return this.onGoHome();
      }
    };
  }

  constructor(protected sessionContext: SessionContextProvider,
              protected applicationContext: ApplicationContextProvider,
              protected router: Router,
              protected propertyService: PropertyService) {
  }
}
