import {ValueObject} from "./ValueObject";
import {ISearchable} from "../../common/interfaces/ISearchable";

export interface IAppEvaluationUserMetaData {
  version: number[];
}

export interface IAppEvaluationUser {
  _meta: IAppEvaluationUserMetaData;
  clientKey: string;
  email: string;
  fullName: string[];
  propertyKeys?: {[key: string]: string};
  availableClientKeys?: {[key: string]: string};
  temporaryPropertyKey: string;
}

export class AppEvaluationUser extends ValueObject<IAppEvaluationUser> implements ISearchable {

  private _searchableString: string = null;

  clientKey: string;
  availableClientKeys: string[] = [];
  fullName: string;
  propertyKeys: string[] = [];

  static array = {
    sortByEmail:  (evaluationUsers: AppEvaluationUser[]) => {
      evaluationUsers.sort( (a, b) => {
        return a.value.email.localeCompare( b.value.email );
      });
    },
    filterByClientKey: (evaluationUsers: AppEvaluationUser[], clientKey: string) : AppEvaluationUser[] => {
      evaluationUsers = evaluationUsers.filter(u => u.clientKey == clientKey || u.availableClientKeys.includes(clientKey));
      evaluationUsers.sort((a, b) => {
        return a.value.email.localeCompare(b.value.email);
      });
      return evaluationUsers;
    }
  }

  get searchableString() : string {
    if( null !== this._searchableString ) {
      return this._searchableString;
    }
    const fullName = typeof this.value.fullName == 'string' ? this.value.fullName : this.value.fullName.join();
    this._searchableString = `${fullName}${this.value.email}`.toLowerCase();
    return this._searchableString;
  }

  public setFullName(fullName: string) {
    this.fullName = fullName;
    this.value.fullName = fullName.split( ' ');
  }

  public static build( value: any, userUid: string = null ): AppEvaluationUser {
      return new AppEvaluationUser(value as IAppEvaluationUser, userUid);
  }

  public hasPropertyKey(propertyKey: string) : boolean {
    return this.propertyKeys.includes(propertyKey) || this.value.temporaryPropertyKey === propertyKey;
  }

  protected onSetValue(value: IAppEvaluationUser | null) {

    this.fullName = null;
    this.propertyKeys = [];
    this.clientKey = null;
    this.availableClientKeys = [];

    if(value) {
      this.clientKey = value.clientKey;

      if(value.fullName) {
        this.fullName = value.fullName.join( ' ' );
      } else {
        const indexOfAt = value.email.indexOf('@');
        const emailName = value.email.substring(0, indexOfAt);
        const emailNameTokens = emailName.split('.');
        this.fullName = emailNameTokens.join(' ');
      }

      if(value.propertyKeys) {
        for(let propertyKey in value.propertyKeys) {
          this.propertyKeys.push(propertyKey);
        }
      }

      if(value.availableClientKeys) {
        for(let availableClientKey in value.availableClientKeys) {
          this.availableClientKeys.push(availableClientKey);
        }
      }
    }
  }

  private constructor(value: IAppEvaluationUser|null, public userUid: string = null) {
    super(value);
    if(value) {
      this.value = value;
    }
  }
}
