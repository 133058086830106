// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

import {IProductEnvironment} from "./FacilitiesEvaluationEnvironment";
import {EProductType} from "../javascript.lib.mojo-base/model/ProductType";

export const environment: IProductEnvironment = {

  name: 'Unity Production',
  filename: 'environment.unity-prod.ts',
  defaultClientKey: '77778484',

  isDevelopment: false,
  isTest: false,
  isProduction: true,

  authenticationConfig: {
    emailLink: false,
    textMessage: false,
    usernamePassword: true,
    google: true
  },

  firebaseConfig: {
    apiKey: "AIzaSyBLdI2-GEWeN_mY5CihNOJ_INiC0OA2rd4",
    authDomain: "mobilitymojo-unity.firebaseapp.com",
    databaseURL: "https://mobilitymojo-unity-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "mobilitymojo-unity",
    storageBucket: "mobilitymojo-unity.appspot.com",
    messagingSenderId: "283128832241",
    appId: "1:283128832241:web:9a2df1bb7e4dedfca90950",
    measurementId: "G-RZZ8Z7Z616"
  },

  cloudFunctionsUrl: 'https://us-central1-mobilitymojo-unity.cloudfunctions.net',

  nocoDbConfig: {
    httpServer: 'https://next.mobilitymojo-spot.com',
    user: 'api-viewer@mobilitymojo.com',
    password: 'IxqWxN;A<s2b=(H',
    authorization: 'Basic bW9iaWxpdHk6bW9qbw==',
    projectTitle: 'PROD-Unity',
    projectId: 'pycigswzt733t86',
  },

  productConfig: {
    firebaseAnswersRoot: '/answers-x/banking',
    productType: EProductType.banking_2024,
    canCommentOnClusters: true,
    canEditClusters: false,
  },

  preview: {
    fireworksEnabled: true,
  },
};
