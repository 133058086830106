import {IFirebaseConnection} from "../../FirebaseConnection";
import {ILogger} from "../../../log/Logger";
import {LoggerFactory} from "../../../log/LoggerFactory";
import {EProductType} from "../../../model/ProductType";
import {IAppClusterAnswer} from "../../../model/app.cluster/AppClusterAnswer";

export class FirebaseClusterAnswer {

  private static _log: ILogger = LoggerFactory.build('FirebaseClusterAnswer');

  static async read(firebaseConnection: IFirebaseConnection, clientKey: string, propertyKey: string, productType: EProductType, clusterId: string, questionReference: string): Promise<IAppClusterAnswer|null> {
    const path = `/${clientKey}/answer-clusters/${productType}/${propertyKey}/answer_cluster/answer_cluster:${clusterId}/answers/${questionReference}`;
    return await firebaseConnection.database2.getValue<IAppClusterAnswer>(path);
  }

  static async write(firebaseConnection: IFirebaseConnection, clientKey: string, propertyKey: string, productType: EProductType, clusterId: string, questionReference: string, value: any) {
    const path = `/${clientKey}/answer-clusters/${productType}/${propertyKey}/answer_cluster/answer_cluster:${clusterId}/answers/${questionReference}`;
    return firebaseConnection.database2.setValue(path, value);
  }
}
