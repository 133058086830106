import {ValueObject} from "../../model/ValueObject";
import {NocoDbProjectProxy} from "../NocoDbProjectProxy";
import {QuestionKey} from "../../model/QuestionKey";
import {IEnumScore, IAppQuestion} from "../../model/AppQuestion";
import {ILogger} from "../../log/Logger";
import {LoggerFactory} from "../../log/LoggerFactory";
import {IListingReponse} from "../ListingReponse";
import {EnumeratedConstantReference} from "../../model/EnumeratedConstant";
import {NocoQuestionType} from "./NocoQuestionType";

export type NocoEvaluationQuestionId = number;

export interface INocoEvaluationQuestion {
  Id: NocoEvaluationQuestionId;
  MysteriousTechColumn: QuestionKey;
  DependencyId: number;
  EnumeratedTypeId: number|null;
  InfoBox: string;
  MiscNotes: string|null;
  PopupWording: string;
  ProductId: number;
  QuestionText: string;
  QuestionTypeId: number;
  Required: number;
  WeightedScore: string;
  TypicalLowValue: number;
  TypicalHighValue: number;
  HelpImageFilename: string;
  DependencyJson: string;
}


interface INocoEnumScore {
  codeAsNumber?: number,
  /**
   * @deprecated : use 'codeAsNumber'
   */
  codeAsString?: string,
  score: number,
}

export class NocoEvaluationQuestion extends ValueObject<INocoEvaluationQuestion>{

  private _log: ILogger = LoggerFactory.build( 'NocoEvaluationQuestion' );

  public isEnum: boolean = false;

  protected onSetValue(value: INocoEvaluationQuestion | null) {
    this.isEnum = false;

    // is it a hack ... No no no not at all ;-)
    if(NocoQuestionType.ENUMERATED_TYPE_ID === value.QuestionTypeId) {
      this.isEnum = true;
    }
  }

  private _getEnumScoring( nocoEnumScores: INocoEnumScore[] ): IEnumScore[] {

    const answer: IEnumScore[] = [];

    try {

      for( const nocoEnumScore of nocoEnumScores ) {

        if( 'undefined' != typeof nocoEnumScore.codeAsNumber ) {

          answer.push( {
            codeAsNumber: nocoEnumScore.codeAsNumber,
            score: nocoEnumScore.score
          });
          continue;
        }

        if( 'undefined' != typeof nocoEnumScore.codeAsString ) {

          const codeAsNumber = EnumeratedConstantReference.asciiCodeToNumber( nocoEnumScore.codeAsString );
          answer.push( {
            codeAsNumber,
            score: nocoEnumScore.score
          });
          continue;
        }

      }
    } catch ( e ) {
      this._log.error( `caught exception 'e'`, 'e', e, 'this.value', this.value );
    }

    return answer;
  }

  public initQuestionScoring(question: IAppQuestion ) {

    let scoring: any = null;
    if( question.type2.typeEnum ) {
      if (!scoring?.[Symbol.iterator]) {
        this._log.warn("Scoring config for multi-choice question is missing or incorrect!", question.nocoDbId);
      } else {
        question.type2.typeEnum.scoring = this._getEnumScoring(scoring);
      }
    }
  }

  constructor(value: INocoEvaluationQuestion | null) {
    super(value);
    if (value) {
      this.value = value;
    }
  }
}

export class NocoEvaluationQuestionSet {

  private static readonly tableName: string = 'evaluation_question';

  values: NocoEvaluationQuestion[] = [];
  valuesById: {[id: number]: NocoEvaluationQuestion} = {};
  valuesByMysteriousTechColumn: {[id: QuestionKey]: NocoEvaluationQuestion} = {};

  public static async getValue( proxy: NocoDbProjectProxy ): Promise<IListingReponse<INocoEvaluationQuestion>> {
    return proxy.getView<INocoEvaluationQuestion>(this.tableName);
  }

  public static async build( proxy: NocoDbProjectProxy ): Promise<NocoEvaluationQuestionSet> {
    const value = await NocoEvaluationQuestionSet.getValue( proxy );
    return new NocoEvaluationQuestionSet( value );
  }


  public constructor( public value: IListingReponse<INocoEvaluationQuestion> ) {
    for( const rowValue of value.list ) {
      const reference = new NocoEvaluationQuestion( rowValue );
      this.values.push( reference );
      this.valuesById[rowValue.Id] = reference;
      if( !reference.value.MysteriousTechColumn ) {
        console.error( "reference.value", reference.value );
      }
      this.valuesByMysteriousTechColumn[ reference.value.MysteriousTechColumn.trim() ] = reference;
    }
  }
}
