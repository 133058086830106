import {Injectable} from '@angular/core';
import {LoggerFactory} from "../javascript.lib.mojo-base/log/LoggerFactory";
import {FirebaseConnectionService} from "../common/service.firebase-connection/FirebaseConnectionService";
import {SessionContextProvider} from "../service.session-context/session-context-provider";
import {AppAudit, EAppAuditState, IAppAudit} from "../javascript.lib.mojo-base/model/AppAudit";
import {FirebaseAudit} from "../javascript.lib.mojo-base/firebase/realtime-database/audit/FirebaseAudit";
import {FirebaseClusterAnswers} from "../javascript.lib.mojo-base/firebase/realtime-database/answer-clusters/FirebaseClusterAnswers";
import {EProductType, ProductTypeOptions} from "../javascript.lib.mojo-base/model/ProductType";
import {environment} from "../environments/environment";
import {IProxyResponse} from "../javascript.lib.mojo-base/firebase/functions/ProxyResponse";
import {IEvaluationState} from "../javascript.lib.mojo-base/model/evaluation/EvaluationStatus";
import {HttpClient} from "@angular/common/http";
import {AppProperty} from "../javascript.lib.mojo-base/model/AppProperty";
import {PropertyService} from "../service.property/property-service";
import {
  FirebaseParentChild
} from "../javascript.lib.mojo-base/firebase/realtime-database/answer-clusters/FirebaseParentChild";

@Injectable(
  {providedIn: 'root'}
)
export class AuditService {

  private static _log = LoggerFactory.build( 'AuditService' );

  constructor(public firebase: FirebaseConnectionService,
              public sessionContext: SessionContextProvider,
              public propertyService: PropertyService,
              public httpClient: HttpClient) {
  }

  async getAllAppAudits() : Promise<AppAudit[]> {
    if (!this.sessionContext.isAdministrator) {
      return;
    }
    return await FirebaseAudit.readAll(this.firebase, this.sessionContext.clientKey);
  }

  async getAppAudits(propertyKeys: string[]) : Promise<AppAudit[] | null> {
    const allAudits =  await FirebaseAudit.readAll(this.firebase, this.sessionContext.clientKey);
    if (!allAudits) {
      return [];
    }

    const propertyKeySet = new Set(propertyKeys);
    return allAudits?.filter((a) => propertyKeySet.has(a.propertyKey));
  }

  async getAppAudit(propertyKey: string) : Promise<IAppAudit | null> {
    return await this.ensureAudit(propertyKey, null, null);
  }

  async getState(propertyKey: string) {
    return (await this.getAppAudit(propertyKey))?.state ?? EAppAuditState.unknown;
  }

  async complete(propertyKey: string) : Promise<void> {
    return this.setState(propertyKey, EAppAuditState.complete);
  }

  async submit(propertyKey: string, sendMessage: boolean = true) : Promise<void> {
    if (sendMessage) {
      try {
        const property = this.propertyService.propertyContext.property;
        const propertyName = property?.value?.name;
        const productType = ProductTypeOptions.getLabel(property?.value?.productType)
        const proxy = await this.sessionContext.buildAuthenticatedProxy(this.httpClient);
        const response: IProxyResponse<boolean> = await proxy.message("submitted", this.sessionContext.clientKey, propertyKey, propertyName, productType, null, null);

        if (response.status !== "0") {
          AuditService._log.error("Error when sending submit message", response, response.status);
        }
      } catch (e) {
        AuditService._log.error("Error when sending submit message", e);
      }
    }
    return this.setState(propertyKey, EAppAuditState.submitted);
  }

  async comment(propertyKey: string, subsection: string, message: string) : Promise<void> {
    if (message.length) {
      try {
        const property = this.propertyService.propertyContext.property;
        const propertyName = property?.value?.name;
        const productType = ProductTypeOptions.getLabel(property?.value?.productType)
        const proxy = await this.sessionContext.buildAuthenticatedProxy(this.httpClient);
        const response: IProxyResponse<boolean> = await proxy.message("commented", this.sessionContext.clientKey, propertyKey, propertyName, productType, subsection, message);

        if (response.status !== "0") {
          AuditService._log.error("Error when sending message", response, response.status);
        }
      } catch (e) {
        AuditService._log.error("Error when sending message", e);
      }
    }
  }

  async setState(propertyKey: string, state: EAppAuditState) : Promise<void> {
    await this.ensureAudit(propertyKey, null, state);
  }

  async setSubsection(propertyKey: string, clusterTypeId: string, score: number, reviewed: boolean) : Promise<void>  {
    const audit = await this.ensureAudit(propertyKey, null, EAppAuditState.reviewInProgress);
    if (!audit.subsections) {
      audit.subsections = [];
    }
    let subsection = audit.subsections.find(s => s.clusterTypeId === clusterTypeId);
    if (!subsection) {
      subsection = {
        clusterTypeId: clusterTypeId,
        score: score,
        reviewed: reviewed,
        timestamp: new Date().toISOString(),
      }
      audit.subsections.push(subsection);
    } else {
      subsection.score = score;
      subsection.reviewed = reviewed;
      subsection.timestamp = new Date().toISOString();
    }
    if (AppAudit.canBeSetToReviewInProgress(audit.state)) {
      audit.state = EAppAuditState.reviewInProgress;
      audit.timestamp = new Date().toISOString();
    }
    await this.write(audit);
  };

  async setScore(propertyKey: string, score: number) : Promise<void> {
    await this.ensureAudit(propertyKey, score, null);
  }

  async hasAnyAnswers(propertyKey: string, productType: EProductType) : Promise<boolean> {
    return await FirebaseClusterAnswers.hasAnyAnswers(this.firebase, this.sessionContext.clientKey, propertyKey, productType);
  }

  async deleteRelationship(propertyKey: string, productType: EProductType, relationshipId: string) : Promise<boolean> {
    return await FirebaseParentChild.delete(this.firebase, this.sessionContext.clientKey, propertyKey, productType, relationshipId);
  }

  private async ensureAudit(propertyKey: string, score: number|null, state: EAppAuditState|null) : Promise<IAppAudit> {
    let audit = (await FirebaseAudit.read(this.firebase, this.sessionContext.clientKey, propertyKey))?.value;
    let dateSubmitted = audit?.dateSubmitted ?? null;
    if (state === EAppAuditState.submitted) {
      dateSubmitted = new Date().toISOString();
    }
    if (!audit) {
      audit = {
        _meta: {
          version: [0]
        },
        propertyKey: propertyKey,
        state: state ?? EAppAuditState.created,
        timestamp: new Date().toISOString(),
        score: score,
        subsections: [],
        dateSubmitted: dateSubmitted,
      };
      await FirebaseAudit.write(this.firebase, this.sessionContext.clientKey, audit);
    } else if (audit.score != score || audit.state != state) {
      let changed: boolean = false;
      if (score != null) {
        audit.score = score;
        changed = true;
      }
      if (state != null) {
        audit.state = state;
        if (dateSubmitted) {
          audit.dateSubmitted = dateSubmitted;
        }
        changed = true;
      }
      if (changed) {
        audit.timestamp = new Date().toISOString();
        await FirebaseAudit.write(this.firebase, this.sessionContext.clientKey, audit);
      }
    }
    return audit;
  }

  private async write(audit: IAppAudit) : Promise<void> {
    await FirebaseAudit.write(this.firebase, this.sessionContext.clientKey, audit);
  }
}
