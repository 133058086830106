import {ValueObject} from "./ValueObject";

export interface IAppAuditMetaData {
  version?: number[];
}

export enum EAppAuditState {
  unknown = "unknown",
  created = "created",
  inProgress = "inProgress",
  complete = "complete",
  submitted = "submitted",
  reviewInProgress = "reviewInProgress",
  reviewComplete = "reviewComplete",
  reportInProgress = "reportInProgress",
  reportSent = "reportSent",
}

export interface IAppAuditSubsection {
  clusterTypeId: string;
  timestamp: string;
  score?: number;
  reviewed: boolean;
}

export interface IAppAudit {
  _meta?: IAppAuditMetaData;
  propertyKey: string;
  state: EAppAuditState;
  timestamp: string;
  score?: number;
  subsections?: IAppAuditSubsection[];
  dateSubmitted?: string;
}

export class AppAudit extends ValueObject<IAppAudit> {

  public static array = {
    sortById: (audits: AppAudit[]) => {
      audits.sort( (a,b) => {
        return a.propertyKey.localeCompare(b.propertyKey);
      })
    }
  }

  public static isWithUser(state: EAppAuditState) : boolean {
    switch (state) {
      case EAppAuditState.unknown:
      case EAppAuditState.created:
      case EAppAuditState.inProgress:
      case EAppAuditState.complete:
        return true;
      default:
        return false;
    }
  }

  public static canBeSetToReviewInProgress(state: EAppAuditState) : boolean {
    switch (state) {
      case EAppAuditState.created:
      case EAppAuditState.submitted:
      case EAppAuditState.inProgress:
      case EAppAuditState.unknown:
        return true;
      default:
        return false;
    }
  }

  public static isReadyForReport(state: EAppAuditState) : boolean {
    switch (state) {
      case EAppAuditState.reviewComplete:
      case EAppAuditState.reportInProgress:
      case EAppAuditState.reportSent:
        return true;
      default:
        return false;
    }
  }

  protected onSetValue(value: IAppAudit | null) {
  }

  constructor(value: IAppAudit|null = null,
               public propertyKey: string|null = null) {
    super(value);
  }
}
