import {Component, EventEmitter, Input, Output, NgModule, OnInit, OnChanges} from "@angular/core";
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {ISearchable} from "../interfaces/ISearchable";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatIconModule} from "@angular/material/icon";
import {SessionStorageWrapper} from "../util/SessionStorageWrapper";

@Component({
  selector: 'search-bar',
  templateUrl: './search-bar.component.html',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule
  ],
  styleUrls: ['search-bar.component.scss']
})

export class SearchBarComponent<T extends ISearchable> implements OnChanges {

  @Input() placeholder: string;
  @Input() unfilteredList: T[];
  @Input() filteredList: T[];
  @Input() key: string;
  @Output() filteredListChange: EventEmitter<T[]> = new EventEmitter<T[]>();
  @Output() searchStringChange: EventEmitter<string> = new EventEmitter<string>();

  formControls = {
    search: new FormControl('',),
  };

  constructor(private sessionStorage: SessionStorageWrapper) {
  }

  ngOnChanges() {
    if (this.key) {
      this.formControls.search.setValue(this.sessionStorage.getItem('searchString', this.key));
      this.onSearch();
    }
  }

  onSearch(): void {
    const searchString = this.formControls.search.value.toLowerCase().trim();

    if (this.key) {
      this.sessionStorage.setItem('searchString', this.key, searchString);
    }
    if (!this.unfilteredList) {
      this.searchStringChange.emit(searchString);
      return;
    }

    this.filteredList = this.unfilteredList;
    if (!searchString || searchString.length === 0) {
      this.filteredListChange.emit(this.filteredList);
      return;
    }

    let searchStrings: string[] = [];
    const quotedPhrases = searchString.match(/"(.*?)"/g);
    if (quotedPhrases) {
      for (let phrase of quotedPhrases) {
        searchStrings.push(phrase.replace(/"/g, ""));
      }
    }
    const unquoted = searchString.replace(/"(.*?)"/g, "").split(" ");
    searchStrings.push(...unquoted.filter(u => u.length > 0));

    this.filteredList = this.filteredList.filter((p) => {
      return searchStrings.some((s) => p.searchableString.includes(s));
    });
    this.filteredListChange.emit(this.filteredList);
  }
}
