export class MatIcons {
  public static icons = {
    audit: "assignment",
    report: "pie_chart",
    users: "groups",
    user: "person",
    clients: "groups",
    properties: "business",
    home: "home",
    logout: "logout",
    backOffice: "admin_panel_settings",
    maintenance: "engineering",
    open: "open_in_new",
    download: "download",
    edit: "edit",
    comment: "comment",
    add_comment: "add_comment",
    building: "corporate_fare",
    bank: "account_balance",
    hotel: "hotel",
    compliant: "verified",
    notCompliant: "block",
    closeAll: "unfold_less",
    openAll: "unfold_more",
    review: "checklist",
    delete: "delete",
    restore: "restore",
    manufacturing: "factory",
  };
}
