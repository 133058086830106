import {IAppQuestion, AppQuestion} from "./AppQuestion";
import {ValueObject} from "./ValueObject";
import { QuestionKey } from "./QuestionKey";
import {ILogger} from "../log/Logger";
import {LoggerFactory} from "../log/LoggerFactory";

export class AppQuestionSet extends ValueObject<IAppQuestion[]> {

  private _log: ILogger = LoggerFactory.build( 'QuestionSet' );

  public questionByKey: {[key: QuestionKey]: AppQuestion} = {};
  public questions: AppQuestion[] = [];
  private _questionsByNocoDbId: {[nocoDbId: number]: AppQuestion} = null;

  public static joinValues( questions: IAppQuestion[][] ): IAppQuestion[] {
    const questionByKey: {[key: QuestionKey]: IAppQuestion} = {};

    for( const questionList of questions ) {
      for( const question of questionList ) {
        questionByKey[question.key] = question;
      }
    }

    return Object.values( questionByKey );
  }

  private _getQuestionByNocoDbId() {
    if( !this._questionsByNocoDbId ) {
      this._questionsByNocoDbId = {};
      for( const question of this.questions ) {
        if( question.value.nocoDbId ) {
          this._questionsByNocoDbId[question.value.nocoDbId] = question;
        }
      }
    }
    return this._questionsByNocoDbId;
  }

  public getQuestionByNocoDbId( nocoDbId: number ): AppQuestion|null {
    const questionsByNocoDbId = this._getQuestionByNocoDbId();
    return questionsByNocoDbId[ nocoDbId ];
  }


  public getQuestionById( id: string|number ): AppQuestion|null {

    if( 'undefined' === typeof id ) {
      return null;
    }

    if( null == id ) {
      return null;
    }

    if( 'number' === typeof id ) {
      const idAsNumber = id as number;
      return this._getQuestionByNocoDbId()[idAsNumber];
    }

    {
      const idAsNumber: number = Number.parseInt( id );
      if (!Number.isNaN(idAsNumber)) {
        return this._getQuestionByNocoDbId()[idAsNumber];
      }
    }

    return this.questionByKey[id];
  }

  protected onSetValue(value: IAppQuestion[] | null) {
    this.questionByKey = {};
    this.questions = [];

    for( const questionValue of value ) {
      const question = new AppQuestion( questionValue );
      this.questions.push( question );
      this.questionByKey[questionValue.key] = question;
    }
  }

  constructor( value: IAppQuestion[] ) {
    super(value);
    this.value = value;
  }
}

