import {Component, OnInit, Input} from '@angular/core';
import {NgClass} from "@angular/common";
import {MatIconModule} from "@angular/material/icon";

@Component({
  selector: 'scroll-to-top',
  templateUrl: './scroll-to-top.component.html',
  standalone: true,
  imports: [
    MatIconModule,
    NgClass
  ],
  styleUrls: ['./scroll-to-top.component.scss']
})

export class ScrollToTopComponent implements OnInit {

  @Input() target!: HTMLElement;
  @Input() isScrolled: boolean;

  scrollToTop() {
    this.target.scrollTo({"top": 0, "left": 0, "behavior": "smooth"});
  }

  ngOnInit() {
  }

  constructor() {
  }
}
