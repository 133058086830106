import {AppAnswer, IAppAnswer} from "./AppAnswer";
import {ValueObject} from "./ValueObject";
import {QuestionKey} from "./QuestionKey";
import {AppQuestionSet} from "./AppQuestionSet";
import {ILogger} from "../log/Logger";
import {LoggerFactory} from "../log/LoggerFactory";
import {AppTypedReference} from "./cg/core/AppTypedReference";
import {ICGAnswerCluster} from "./cg/core/CGAnswerCluster";
import {AppQuestion} from "./AppQuestion";
import {MMBaseException} from "../util/MMBaseException";
import {IScore} from "./score/IScore";


export class AppAnswerSet extends ValueObject<{ [key: QuestionKey]: IAppAnswer; }> {

  private _log: ILogger = LoggerFactory.build('AppAnswerSet');

  answerByKey: { [key: QuestionKey]: AppAnswer } = {};
  answers: AppAnswer[] = [];

  private _completed = false;
  private _score: IScore = null;

  getAnswers(keys: QuestionKey[]): AppAnswer[] {
    const response: AppAnswer[] = [];
    for (const key of keys) {
      const question = this.questions.questionByKey[key];
      if (!question) {
        this._log.error(`!question .${key}.`, 'key', key);
        continue;
      }
      const answer = this.getAnswer(question);
      response.push(answer);
    }

    return response;
  }

  /**
   * Will create an answer if required
   * @param question
   */
  getAnswer(question: AppQuestion): AppAnswer {


    const questionKey = question.value.key;
    let reference = this.answerByKey[questionKey];

    if (!reference) {

      const answerValue = this.value[questionKey];
      if (answerValue) {
        reference = new AppAnswer(question, answerValue);
      } else {
        reference = new AppAnswer(question);
        this.value[questionKey] = reference.value;
      }

      if (question.value.dependant) {
        const dependantQuestion = this.questions.getQuestionById(question.value.dependant.questionKey);

        if (!dependantQuestion) {
          console.warn("!dependantQuestion", "question.value.dependant.questionKey: " + question.value.dependant.questionKey)
        } else {
          if (question.value.key === question.value.dependant.questionKey) {
            throw MMBaseException.build('AppAnswerSet.getAnswer',
              'question.value.key === question.value.dependant.questionKey',
              {
                'questionKey': questionKey,
              });
          }

          try {
            const dependantAnswer = this.getAnswer(dependantQuestion);
            reference.dependant = dependantAnswer;
          } catch (e) {
            this._log.error(e, 'question', question, 'dependantQuestion', dependantQuestion);
          }
        }

      }

      this.answerByKey[questionKey] = reference;
    }

    return reference;
  }

  scrubMarkupTagsFromText(originalValue: string) {

    let answer = originalValue;
    {
      const openingTag = /<([_a-zA-Z0-9]*)>/i; // https://regex101.com/r/NMrXnd/1
      let matches = originalValue.match(openingTag);
      if (matches) {
        for (const match of matches) {

          answer = answer.replace(`<${match}>`, `-${match}-`);
        }
      }
    }
    {
      const closingTag = /<\/([_a-zA-Z0-9]*)>/i; // https://regex101.com/r/tKJTNv/1
      let matches = originalValue.match(closingTag);
      if (matches) {
        for (const match of matches) {

          answer = answer.replace(`</${match}>`, `-${match}-`);
        }
      }
    }

    return answer;
  }

  // report-7152.remediation.md: Page 19: Lack of Input Validation(stored)
  scrubMarkupTagsFromTextAnswers() {

    for (const questionKey of Object.keys(this.answerByKey)) {
      const answer: AppAnswer = this.answerByKey[questionKey];
      if (answer.question.isText) {
        if (answer.value.value && 'string' == typeof answer.value.value) {

          answer.value.value = this.scrubMarkupTagsFromText(answer.value.value);
        }
      }
    }
  }


  private _init() {

    // a hack to overcome '_init()' being called in the ctor but the field 'this.questions' has *NOT* been setup
    if (!this.questions) {
      return;
    }
    this.answerByKey = {};
    this.answers = [];

    for (const questionKey of Object.keys(this.value)) {

      const question = this.questions.questionByKey[questionKey];
      if (!question) {

        this._log.warn('!question; question associated with answer not found', questionKey);
        continue;
      }

      const anAnswer = new AppAnswer(question, this.value[questionKey]);
      this.answers.push(anAnswer);
      this.answerByKey[questionKey] = anAnswer;
    }


    for (const answer of this.answers) {
      if (answer.question.value.dependant) {
        const dependantQuestionKey = answer.question.value.dependant.questionKey;
        const dependantAnswer = this.answerByKey[dependantQuestionKey];
        if (dependantAnswer) {
          answer.dependant = dependantAnswer;
        }
      }
    }
  }

  /**
   * Shallow subset
   * @param keys
   */
  public getSubset(keys: QuestionKey[]): AppAnswerSet {

    const answer = new AppAnswerSet(this.questions, {});

    for (const key of keys) {
      const questionAnswer = this.answerByKey[key];

      if (!questionAnswer) {
        this._log.warn('!questionAnswer; answer not found', 'key', key);
        continue;
      }

      answer.value[key] = questionAnswer.value;
      answer.answers.push(questionAnswer);
      answer.answerByKey[key] = questionAnswer;
    }

    return answer;
  }

  public static buildFromAnswerCluster(questions: AppQuestionSet, value: ICGAnswerCluster): AppAnswerSet {

    const answerSetValue: { [key: QuestionKey]: IAppAnswer; } = {};
    for (const referenceString of Object.keys(value.answers)) {

      const answerValue = value.answers[referenceString];
      const reference = AppTypedReference.build(referenceString);
      answerSetValue[reference.value.id] = answerValue.answer;
    }

    const response = new AppAnswerSet(questions, answerSetValue);
    return response;
  }

  public static joinValues(values: { [key: QuestionKey]: IAppAnswer }[]): { [key: QuestionKey]: IAppAnswer } {

    const answer: { [key: QuestionKey]: IAppAnswer } = {};

    for (const value of values) {
      for (const key of Object.keys(value)) {
        answer[key] = value[key];
      }
    }

    return answer;
  }


  protected onSetValue(value: { [key: QuestionKey]: IAppAnswer } | null) {
    this._init();
  }

  constructor(public questions: AppQuestionSet,
              value: { [key: QuestionKey]: IAppAnswer; } | null) {

    super(value);
    this.value = value;
  }

}


