import { Component, Input, NgZone, OnInit} from '@angular/core';
import {PhotosProvider} from "../../module.evaluation-services/service.photos/photos";
import {AppQuestion} from "../../../javascript.lib.mojo-base/model/AppQuestion";
import {AlertDialogComponent} from "../../module.base-components/component.alert-dialog/alert-dialog";
import {MatDialog} from "@angular/material/dialog";
import {ILogger} from "../../../javascript.lib.mojo-base/log/Logger";
import {AppAnswer} from "../../../javascript.lib.mojo-base/model/AppAnswer";
import {LoggerFactory} from "../../../javascript.lib.mojo-base/log/LoggerFactory";
import {SessionContextProvider} from "../../../service.session-context/session-context-provider";
import {PropertyService} from "../../../service.property/property-service";


export class FileReaderProgressEvent extends Event {

}

@Component({
  selector: 'question-photo',
  templateUrl: 'question-photo.html',
  styleUrls: ['question-photo.scss']
})
export class QuestionPhotoComponent implements OnInit{

  private log: ILogger = LoggerFactory.build( 'QuestionPhotoComponent' );

  @Input() public answer: AppAnswer;
  @Input() public maxImageCount = 3;
  @Input() public questionLabel = null;

  async init() {

    if( !this.questionLabel ) {
      this.questionLabel = this.answer.question.value.label;
    }

    if( this.answer.question.value.type !== AppQuestion.TYPE_PHOTO ) {
      this.log.error( 'this.answer.question.value.type !== QuestionReference.TYPE_PHOTO' );
    }

    await this.photos.init( this.sessionContext.clientKey, this.propertyService.propertyContext.propertyKey, this.answer );
  }


  ngOnInit(): void {
    this.init();
  }

  remove( index: number ) {
    this.photos.remove( index );
  }

  readAsArrayBuffer(fileList: FileList, base64Encoded: string ) {
    const fileReader = new FileReader();

    fileReader.onload = (e :Event) => {

      this.zone.run( () => {

        const target: any = e.target; // TS does not like extracting 'result' from type 'EventTarget'
        this.photos.push( base64Encoded, target.result );
      } );
    }

    fileReader.onerror = (ev: FileReaderProgressEvent) => {
      this.zone.run( () => this.onError( ev ) );
    }

    fileReader.readAsArrayBuffer(fileList[0]);
  }

  readAsDataURL( fileList: FileList ) {

    const fileReader = new FileReader();

    fileReader.onload = (e :Event) => {
      this.zone.run( () => {
        var target: any = e.target; // TS does not like extracting 'result' from type 'EventTarget'
        this.readAsArrayBuffer( fileList, target.result );
      } );
    }

    fileReader.onerror = (ev: FileReaderProgressEvent) => {
      this.zone.run( () => this.onError( ev ) );
    }

    fileReader.readAsDataURL(fileList[0]);
  }

  onImageSelected($event) {

    this.log.debug( 'onImageSelected', $event );

    const fileList: FileList = $event.target.files;
    if( !fileList ) {
      this.log.error( 'onImageSelected', '!fileList' );
      return;
    }

    if( !fileList.length ) {
      this.log.error( 'onImageSelected', '!fileList.length' , fileList );
      return;
    }

    this.readAsDataURL( fileList );
  }

  onError( ev: FileReaderProgressEvent ) {

    this.log.error( 'onError', ev );

    var target:any = ev.target; // TS does not like extracting 'error' from type 'EventTarget'

    let subTitle = "code: undefined";

    if( target.error && target.error.code ) {

      subTitle = target.error.code;
    }

    this.log.logError( 'onError', event => {
      event.addContext( 'subTitle', subTitle );
    });

    AlertDialogComponent.show( this.dialog, 'Error uploading image', subTitle );

  }

  constructor(public photos: PhotosProvider,
              public sessionContext: SessionContextProvider,
              public propertyService: PropertyService,
              private zone: NgZone,
              public dialog: MatDialog
  ) {
  }


}
