import {Component, OnInit} from '@angular/core';
import {ILogger} from "../javascript.lib.mojo-base/log/Logger";
import {LoggerFactory} from "../javascript.lib.mojo-base/log/LoggerFactory";
import {ActivatedRoute, Router} from "@angular/router";
import {NocoDbProduct} from "../javascript.lib.mojo-base/nocodb/NocoDbProduct";
import {AppRouteManifest} from "../app/AppRouteManifest";
import {SessionContextProvider} from "../service.session-context/session-context-provider";
import {FirebaseCluster} from "../javascript.lib.mojo-base/firebase/realtime-database/answer-clusters/FirebaseCluster";
import {FirebaseConnectionService} from "../common/service.firebase-connection/FirebaseConnectionService";
import {AppCluster} from "../javascript.lib.mojo-base/model/app.cluster/AppCluster";
import {AppPageDefinitionSet} from "../javascript.lib.mojo-base/model/app/AppPageDefinitionSet";
import {AppAnswerSet} from "../javascript.lib.mojo-base/model/AppAnswerSet";
import {AppClusterAnswers, IAppClusterAnswers} from "../javascript.lib.mojo-base/model/app.cluster/AppClusterAnswers";
import {AppAnswer} from "../javascript.lib.mojo-base/model/AppAnswer";
import {
  FirebaseClusterAnswers
} from "../javascript.lib.mojo-base/firebase/realtime-database/answer-clusters/FirebaseClusterAnswers";
import {FirebaseSaver2} from "../common/util/FirebaseSaver2";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {MatDialog} from "@angular/material/dialog";
import {PhotosProvider} from "../common/module.evaluation-services/service.photos/photos";
import {AppProperty} from "../javascript.lib.mojo-base/model/AppProperty";
import {AppAnswerPage} from "../javascript.lib.mojo-base/model/AppAnswerPage";
import {EAppReferenceType} from "../javascript.lib.mojo-base/model/cg/core/AppReferenceType";
import {SessionContextState} from "../common/service.session-context/BaseSessionContext";
import {Subscriptions2} from "../javascript.lib.mojo-base/util/Subscriptions2";
import {PropertyService} from "../service.property/property-service";
import {SubsectionScoreCalculator} from "../javascript.lib.mojo-base/model/scoring/SubsectionScoreCalculator";
import {EProductType} from "../javascript.lib.mojo-base/model/ProductType";
import {
  FirebaseClusterAnswer
} from "../javascript.lib.mojo-base/firebase/realtime-database/answer-clusters/FirebaseClusterAnswer";
import firebase from "firebase/compat";
import App = firebase.app.App;
import {AppQuestionSet} from "../javascript.lib.mojo-base/model/AppQuestionSet";

@Component({
  selector: 'page-cluster-page',
  styleUrls: ['page-cluster-page.component.scss'],
  templateUrl: './page-cluster-page.component.html'
})
export class PageClusterPageComponent implements OnInit {

  private _log: ILogger = LoggerFactory.build('PageClusterPageComponent');
  private _firebaseSaver: FirebaseSaver2;

  private answerCorrections: any[] = [];

  public clusterAnswers: AppClusterAnswers;

  public property: AppProperty;
  public cluster: AppCluster;
  public isSaving: boolean = false;
  public activeIndex = 0;
  public sectionIndex = 0;
  public subSectionIndex = 0;
  public firstQuestionOnPageIndex = 0;
  public pageDefinitions: AppPageDefinitionSet;
  public appAnswerSet: AppAnswerSet;
  public answers: AppAnswer[];
  public pages: AppAnswerPage[];
  public title: string;
  public subscriptions: Subscriptions2 = new Subscriptions2();
  public clusters: AppCluster[];

  constructor(public router: Router,
              public route: ActivatedRoute,
              public sessionContext: SessionContextProvider,
              public propertyService: PropertyService,
              public firebase: FirebaseConnectionService,
              public afDb: AngularFireDatabase,
              public dialog: MatDialog,
              public photos: PhotosProvider
  ) {
    this._firebaseSaver = new FirebaseSaver2(afDb, photos, dialog);
  }

  async ngOnInit(): Promise<void> {
    this.subscriptions.subscribe(this.sessionContext.stateSubject, (state) => {
      if (state === SessionContextState.UserIsReady) {
        this.subscriptions.subscribe(this.propertyService.propertySubject, () => {
          this.route.paramMap.subscribe(async (params) => {
            await this._onInit();
          });
        });
      }
    });
  }

  async save(): Promise<boolean> {
    this.clusterAnswers.update(this.appAnswerSet);
    await this._doSave();
    await FirebaseCluster.write(this.firebase, this.sessionContext.clientKey, this.property.propertyKey, this.propertyService.productType, this.cluster);
    return true;
  }

  async onPrevious(): Promise<void> {
    await this.save();

    if (this.activeIndex === 0) {
      this._goToAudit();
    }

    let anyQuestionOnPageNeedsAnAnswer: boolean = false;
    for (let candidatePageIndex = this.activeIndex - 1; candidatePageIndex >= 0; candidatePageIndex--) {
      anyQuestionOnPageNeedsAnAnswer = this._anyQuestionOnPageNeedsAnAnswer(candidatePageIndex)
      if (anyQuestionOnPageNeedsAnAnswer) {
        await this._goToPage(candidatePageIndex);
        break;
      }
    }
    if (!anyQuestionOnPageNeedsAnAnswer) {
      this._goToAudit();
    }
  }

  async onNext(): Promise<void> {
    await this.save();

    if (this.activeIndex === this.pages.length) {
      this._goToAudit();
    }

    let anyQuestionOnPageNeedsAnAnswer: boolean = false;
    for (let candidatePageIndex = this.activeIndex + 1; candidatePageIndex < this.pages.length; candidatePageIndex++) {
      anyQuestionOnPageNeedsAnAnswer = this._anyQuestionOnPageNeedsAnAnswer(candidatePageIndex);
      if (anyQuestionOnPageNeedsAnAnswer) {
        await this._goToPage(candidatePageIndex);
        break;
      }
    }
    if (!anyQuestionOnPageNeedsAnAnswer) {
      this._goToAudit();
    }
  }

  private async _doSave() {
    this.isSaving = true;
    try {
      this.appAnswerSet.scrubMarkupTagsFromTextAnswers(); // report-7152.remediation.md: Page 19: Lack of Input Validation(stored)
      this.clusterAnswers.value.score = this._calculateSubsectionScore();
      this.clusterAnswers.update(this.appAnswerSet);

      console.info("saving", this.clusterAnswers);

      await FirebaseClusterAnswers.write(this.firebase, this.sessionContext.clientKey, this.property.propertyKey, this.propertyService.productType, this.clusterAnswers);
      await this._firebaseSaver.savePhotos(this.sessionContext.clientKey, this.propertyService.propertyContext.propertyKey);
    } finally {
      this.isSaving = false;
    }
  }

  private _calculateSubsectionScore(): number | null {
    try {
      let subsectionScoreCalculator = new SubsectionScoreCalculator();
      return subsectionScoreCalculator.calculate(this.cluster, this.answers);
    } catch (e) {
      this._log.error("Error calculating subsection score", "this.cluster._self.value.id", this?.cluster?._self?.value?.id ?? "??", e);
    }
    return null
  }

  private _anyQuestionOnPageNeedsAnAnswer(pageIndex: number): boolean | null {
    if (pageIndex < 0 || pageIndex > this.pages.length) {
      return null;
    }
    return this.pages.find((p: AppAnswerPage): boolean => p.index === pageIndex)
      ?.answers.some((a: AppAnswer) => a.answer.value.needsAnswer);
  }

  private _goToAudit(): void {
    AppRouteManifest.AUDIT.navigate(this.router, {'propertyKey': this.property.propertyKey});
  }

  private async _goToPage(pageIndex: number): Promise<void> {
    const sectionIndex = await AppRouteManifest.CLUSTER_PAGE.getSectionIndex(this.route);
    const subSectionIndex = await AppRouteManifest.CLUSTER_PAGE.getSubSectionIndex(this.route);
    AppRouteManifest.CLUSTER_PAGE.navigateToPage(this.router, this.cluster._self.toString, pageIndex, sectionIndex, subSectionIndex);
  }

  private async _onInit() {
    this.activeIndex = await AppRouteManifest.CLUSTER_PAGE.getActiveIndex(this.route);
    this.sectionIndex = await AppRouteManifest.CLUSTER_PAGE.getSectionIndex(this.route);
    this.subSectionIndex = await AppRouteManifest.CLUSTER_PAGE.getSubSectionIndex(this.route);

    await this._ensureProperty();

    const clusterId = await AppRouteManifest.CLUSTER_PAGE.getClusterId(this.route);
    if (!this.cluster) {
      this.cluster = await FirebaseCluster.read(this.firebase, this.sessionContext.clientKey, this.property.propertyKey, this.propertyService.productType, clusterId);
    }

    const product = NocoDbProduct.INSTANCE;
    this._augmentClusterAttributes(product);

    if (!this.title || !this.pageDefinitions) {
      this.pageDefinitions = product.clusterQuestions.toPageDefinitions(this.cluster, product.evaluationQuestions, this.property.value.countryCode);
      this.title = this.cluster.value.name;
    }

    await this._onInitAnswers();

    this.firstQuestionOnPageIndex = this.getPreviousPagesQuestionCount() + 1;
    this._log.debug(`${this.sectionIndex} ${this.subSectionIndex} starting with... ${this.firstQuestionOnPageIndex}`);
  }

  private async _ensureProperty(): Promise<void> {
    if (this.propertyService.propertyContext) {
      this.property = this.propertyService.propertyContext.property;
    }
    if (!this.property) {
      throw new Error("No property!");
    }
  }

  private _augmentClusterAttributes(product: NocoDbProduct): void {
    const clusterTypeId = this.cluster.value.clusterTypeId;
    try {
      const productType = this.property.value.productType;
      const productId = product.products.getProduct(productType).value.Id;
      const cluster = product.clusters.values.find(c => c.value.Id === clusterTypeId);
      const productCluster = product.productClusters.getForProduct(productId, clusterTypeId).value;
      if (cluster?.value) {
        this.cluster.value.name = cluster.value.Name;
      }
      this.cluster.value.optional = productCluster.Optional;
      this.cluster.value.essential = productCluster.Essential;
      this.cluster.value.weight = productCluster.Weight;
    } catch (e) {
      this._log.error("Unable to augment cluster", "clusterTypeId", clusterTypeId);
    }
  }

  private async _onInitAnswers(): Promise<void> {

    if (!this.appAnswerSet) {
      const questionSet = this.pageDefinitions.toQuestionSet(this.propertyService.propertyContext.product.questions);
      await  this._setupClusterAnswers();

      this.appAnswerSet = this.clusterAnswers.toAnswerSet(questionSet);
      await this._correctGeneralBathroomDoorWidths();
      this.answers = this.appAnswerSet.getAnswers(questionSet.questions.map((q) => q.value.key));

      if (this.answerCorrections.length > 0) {
        for (const correction of this.answerCorrections) {
          const answerToCorrect = this.answers.find(a => a.question.value.key === correction.key);
          if (answerToCorrect) {
            answerToCorrect.answer.value.value = correction.value;
            answerToCorrect.answer.value.hasAnswer = true;
          }
        }
        await this._doSave();
        await  this._setupClusterAnswers();
        this.appAnswerSet = this.clusterAnswers.toAnswerSet(questionSet);
        this.answers = this.appAnswerSet.getAnswers(questionSet.questions.map((q) => q.value.key));
      }
    }

    this._initPages();
    this._initDependencies();
  }

  private async _setupClusterAnswers() {
    const clusterAnswersValue: IAppClusterAnswers =
      await FirebaseClusterAnswers.read(this.firebase, this.sessionContext.clientKey, this.property.propertyKey, this.propertyService.productType, this.cluster);

    if (clusterAnswersValue) {
      this.clusterAnswers = new AppClusterAnswers(clusterAnswersValue);
    } else { // first time answering these questions
      this.clusterAnswers = AppClusterAnswers.build(this.cluster._self.value.id);
    }
  }

  private async _correctGeneralBathroomDoorWidths(): Promise<void> {
    if (this.property.value.productType === EProductType.facilities) {
      const typeOfGeneralBathroom = this.appAnswerSet.answers.find(a => a.value.questionKey === '826');
      if (typeOfGeneralBathroom && typeOfGeneralBathroom.answer.value.value === 272) {
        const accessibleStallWithinGeneralBathroom = await this._isAccessibleStallWithinGeneralBathroom();
        if (accessibleStallWithinGeneralBathroom) {
          this.answerCorrections.push({key: '826', value: 341});
          const existingWidthAnswer = this.appAnswerSet.answers.find(a => a.value.questionKey === 'Cjp6z');
          if (existingWidthAnswer?.value.value) {
            this.answerCorrections.push({key: '1933', value: existingWidthAnswer.value.value});
          }
        }
      }
    }
  }

  private async _isAccessibleStallWithinGeneralBathroom(): Promise<boolean> {
    const appClusterSet = await FirebaseCluster.readAll(this.firebase, this.sessionContext.clientKey, this.property.propertyKey, this.propertyService.productType);

    const accessibleBathrooms = appClusterSet.values.find(c => c.value.clusterTypeId === 'facilities:accessible_bathrooms');

    if (accessibleBathrooms) {
      const typeOfAccessibleBathroom = await FirebaseClusterAnswer.read(
        this.firebase, this.sessionContext.clientKey, this.property.propertyKey,
        this.property.value.productType, accessibleBathrooms._self.value.id, 'answer_enum:828');

      return typeOfAccessibleBathroom.answer.value == '269';
    }

    return false;
  }

  private _initPages(): void {
    this.pages = [];
    this.pageDefinitions.value.forEach((pageDefinition, index) => {
      const questionKeys = pageDefinition.value.questionKeys;
      const pageAnswers = this.appAnswerSet.getAnswers(questionKeys);
      const page: AppAnswerPage = {index: index, answers: pageAnswers};
      this.pages.push(page);
    });
  }

  private _initDependencies(): void {

    this.answers.forEach((value, index) => {

      if (value.dependant) {
        const type = value.dependant.toAnswerReference().value.type;
        if (type === EAppReferenceType.answer_boolean ||
          type === EAppReferenceType.answer_enum ||
          type === EAppReferenceType.answer_ternary) {

          this.setNeedsAnswer(value);

          value.dependantSubscription = value.dependant.getSubject().subscribe((dependant: AppAnswer) => {
            this.setNeedsAnswer(value);
          });
        }
      }
    });
  }

  private setNeedsAnswer(appAnswer: AppAnswer): void {
    if (!appAnswer?.value) {
      return;
    }

    const existingNeedsAnswer = appAnswer.value.needsAnswer;
    const newNeedsAnswer = appAnswer.dependant.isTruthy(appAnswer?.question?.value?.dependant) ?? false;
    if (existingNeedsAnswer != newNeedsAnswer) {
      appAnswer.value.needsAnswer = newNeedsAnswer;
    }
  }

  private getPreviousPagesQuestionCount(): number {
    if (this.activeIndex === 0) {
      return 0;
    }
    let count: number = 0;
    for (let i = 0; i < this.activeIndex; i++) {
      count += this.pageDefinitions.value[i].value.questionKeys.length;
    }
    return count;
  }
}
