import {ValueObject} from "./ValueObject";
import {EnumeratedConstantReference, IEnumeratedConstant} from "./EnumeratedConstant";
import {QuestionKey} from "./QuestionKey";

export interface IAppDependantDescriptor {

  questionKey: QuestionKey;

  falsyEnumCodes?: number[]; // should be available when the dependant has a value of 'enum'
  truthyEnumCodes?: number[]; // should be available when the dependant has a value of 'enum'

  falsyBooleanValue?: boolean;
  truthyBooleanValue?: boolean;

  truthyTernaryCodes?: number[];
  isEssential?: boolean;
}

export interface ITypeBoolean {
}

export interface IEnumScore {
  codeAsNumber: number,
  score: number
}

export interface ITypeEnum {
  options: IEnumeratedConstant[];
  scoring: IEnumScore[];
  enumeratedTypeId?: number;
  legacyOptions?: IEnumeratedConstant[];
}

export interface ITypeCmMeasurement {
}

export interface ITypeInteger {
}

export interface ITypeTernary {
}

export interface ITypeText {
}

export interface IType {
  typeBoolean?: ITypeBoolean;
  typeCmMeasurement?: ITypeCmMeasurement;
  typeEnum?: ITypeEnum;
  typeFloat?: any;
  typeInteger?: ITypeInteger;
  typePhoto?: any;
  typeTernary?: ITypeTernary;
  typeText?: ITypeText;
}

export interface IAppQuestion {
  key: QuestionKey; // uuid
  label: string;
  helpImageFilename: string;
  helpText: string;
  popupLabel: string | null;
  /** @deprecated use `type2` **/
  type: string; // see below
  type2?: IType;
  enumOptions?: IEnumeratedConstant[]; // should be available when type has a value of 'enum'
  dependant?: IAppDependantDescriptor;
  nocoDbId?: number;
  typicalLowValue?: number;
  typicalHighValue?: number;
  friendlyLabel?: string;
  dependencyRule: string;
  scoringRule: string;
  mobilityWeight: number;
  visualWeight: number;
  auditoryWeight: number;
  cognitiveWeight: number;
}

export class AppQuestion extends ValueObject<IAppQuestion> {

  static readonly TYPE_BOOLEAN: string = 'boolean';
  static readonly TYPE_CM_MEASUREMENT: string = 'cmMeasurement'; // single-line
  static readonly TYPE_ENUM: string = 'enum';
  static readonly TYPE_FLOAT: string = 'float';
  static readonly TYPE_INTEGER: string = 'integer';
  static readonly TYPE_LINE: string = 'line'; // single-line
  static readonly TYPE_PHOTO: string = 'photo'; // camera image
  static readonly TYPE_TEXT: string = 'text'; // multi-line
  static readonly TYPE_TERNARY: string = 'ternary'; // 1/yes/true, 0/no/false, 0x6e2f61/7221089/not-applicable/undefined


  // TODO: kill this ...
  static readonly KEY_IDS: {
    COMMENTS_QUESTION_ID: number,
    COMMENTS_QUESTION_KEY: string
  } = {
    COMMENTS_QUESTION_ID: 274,
    COMMENTS_QUESTION_KEY: "QZ_dz"
  };

  isBoolean = false;
  isCmMeasurement = false;
  isEnum = false;
  isFloat = false;
  isInteger = false;
  isLine = false;
  isPhoto = false;
  isTernary = false;
  isText = false;

  static validateEnums( questions: IAppQuestion[] ): boolean {

    let answer = true;

    for( const question of questions ) {
      if( question.type !== AppQuestion.TYPE_ENUM ) {
        continue;
      }

      if( !question.enumOptions ) {
        console.error( 'Question', 'validateEnums', 'question.key', question.key );
        answer = false;
      }
    }
    return answer;
  }

  protected onSetValue(value: IAppQuestion ) {
    const trimmedKey = value.key.trim();
    if (value.key != trimmedKey) {
      value.key = trimmedKey;
    }
  }

  constructor(value: IAppQuestion) {
    const trimmedKey = value.key.trim();
    if (value.key != trimmedKey) {
      value.key = trimmedKey;
    }
    super(value);

    const type2: IType = {};

    if ( value.type === AppQuestion.TYPE_BOOLEAN ) {
      type2.typeBoolean = {};
      this.isBoolean = true;
    } else if ( value.type === AppQuestion.TYPE_CM_MEASUREMENT ) {
      type2.typeCmMeasurement = {}
      this.isCmMeasurement = true;
    } else if ( value.type === AppQuestion.TYPE_ENUM ) {
      type2.typeEnum = {
        options: value.enumOptions,
        scoring: [],
      };
      this.isEnum = true;
    } else if ( value.type === AppQuestion.TYPE_FLOAT ) {
      type2.typeFloat = {};
      this.isFloat = true;
    } else if ( value.type === AppQuestion.TYPE_INTEGER ) {
      type2.typeInteger = {};
      this.isInteger = true;
    } else if ( value.type === AppQuestion.TYPE_PHOTO ) {
      type2.typePhoto = {};
      this.isPhoto = true;
    } else if ( value.type === AppQuestion.TYPE_TERNARY ) {
      type2.typeTernary = {};
      this.isTernary = true;
      this.value.enumOptions = [
        EnumeratedConstantReference.notApplicable,
        EnumeratedConstantReference.no,
        EnumeratedConstantReference.yes,
      ];
    } else if ( value.type === AppQuestion.TYPE_TEXT ) {
      type2.typeText = {};
      this.isText = true;
    } else  {
      console.error(`AppQuestion constructor can't determine type`, 'id', value.nocoDbId, 'key', value.key);
    }

    if(!value.type2) {
      value.type2 = type2;
    }
  }
}
