import {AppProperty} from "../javascript.lib.mojo-base/model/AppProperty";
import {IProduct} from "../model.product/IProduct";
import {LoggerFactory} from "../javascript.lib.mojo-base/log/LoggerFactory";
import {SessionContextProvider} from "../service.session-context/session-context-provider";
import {ProductBuilder} from "../service.product-builder/product-builder.service";

export class PropertyContext {

  private static log = LoggerFactory.build( 'PropertyContext' );

  public propertyKey: string;
  public completedPageHasBeenShown: boolean = false;
  public showCongratulationsText: boolean = true;

  private static async _getProduct( property: AppProperty,
                              productBuilder: ProductBuilder ): Promise<IProduct> {
    return await productBuilder.build(property.value.productType);
  }

  public static async build(sessionContext: SessionContextProvider,
                            property: AppProperty,
                            productBuilder: ProductBuilder): Promise<PropertyContext> {
    const product : IProduct = await PropertyContext._getProduct( property, productBuilder );
    return new PropertyContext(property, product, sessionContext);
  }

  private constructor( public property: AppProperty,
                       public product: IProduct,
                       public sessionContext: SessionContextProvider,) {
    this.propertyKey = property.propertyKey;
  }
}
