
<div fxLayout="column" fxLayoutGap="10px" style="padding: 10px 8px 0px 8px;">

  <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="8px">

    <div fxFlex="16" [fxFlex.xs]="16">
      <help [answer]="questionAnswer.answer">
      </help>
    </div>

    <div fxFlex="84" [fxFlex.xs]="84">

      <div style="display: none">
        {{questionAnswer.question.value.key}}
      </div>
      <span text-wrap style="margin-top: 6px; margin-bottom: 4px;"
            [ngClass]="{'disabledQuestion':getDisabled()}">
        <span *ngIf="showFriendlyLabel">{{getQuestionNumber()}}.</span>
        {{questionAnswer.answer.question.value.label}}
      </span>
    </div>

  </div>


  <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="8px">

    <div fxFlex="16" [fxFlex.xs]="16">
    </div>

    <div fxFlex="84" [fxFlex.xs]="84">

      <mat-form-field cclass="mat-form-field">
        <textarea matInput
                  cdkAutosizeMinRows="5"
                  [placeholder]="placeholder"
                  [disabled]="getDisabled()"
                  [(ngModel)]="questionAnswer.answer.value.value"
        >
        </textarea>
      </mat-form-field>
    </div>

  </div>

</div>

