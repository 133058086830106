import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Validators} from "@angular/forms";
import {AppQuestion} from "../../../javascript.lib.mojo-base/model/AppQuestion";
import {AppAnswer} from "../../../javascript.lib.mojo-base/model/AppAnswer";
import {ValueHelper} from "../../../javascript.lib.mojo-base/util/ValueHelper";
import {LoggerFactory} from "../../../javascript.lib.mojo-base/log/LoggerFactory";
import {BaseQuestion} from "../BaseQuestion";

@Component({
  selector: 'question-integer',
  templateUrl: 'question-integer.html',
  styleUrls: ['question-integer.scss']
})

export class QuestionIntegerComponent extends BaseQuestion implements OnInit, OnDestroy {

  _log = LoggerFactory.build( 'QuestionIntegerComponent' );

  @Input() required: boolean = true;

  @Input() set disabled(value: boolean) {
    super.disabled = value;
    if( value ) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }

  set model( value: string ) {

    const numberValue = ValueHelper.valueToNumber( value )
    this._log.debug( 'set model', value, numberValue );

    if( null === numberValue ) {
      this.answer.value.hasAnswer = false;
      return;
    }

    this.answer.value.value = numberValue;
    this.answer.value.hasAnswer = true;

    // hacky way to get the subscription to trigger ...
    this.answer.value = this.answer.value;
  }

  get model() {
    return this.answer.value.value;
  }

  hasIssues(): boolean {
    if( !this.required ) {
      return false;
    }

    if( this.formControl.disabled ) {
      return false;
    }

    if( null === this.formControl.errors ) {
      return false;
    } else {
      this.formControl.markAsTouched();
      this.formControl.markAsDirty();
    }

    return true;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.validateType(AppQuestion.TYPE_INTEGER);

    {
      const validators = [
        Validators.min(0),
        Validators.max(Number.MAX_VALUE),
      ];
      if( this.required ) {
        validators.push( Validators.required );
      }
      this.formControl.setValidators( validators );
    }

    const dependantAnswer = this.answer.dependant;

    if( dependantAnswer ) {
      this._dependantSubscription = dependantAnswer.getSubject().subscribe( (dependant: AppAnswer ) => {
        const isTruthy = dependant.isTruthy( this.answer.question.value.dependant );
        this.disabled = !isTruthy;
      } );
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  constructor() {
    super();
  }
}



